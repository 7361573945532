<!--
 * @Description: 表格标题
 * @Author: ChenXueLin
 * @Date: 2021-08-17 09:20:51
 * @LastEditTime: 2022-09-09 09:37:25
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="table-title" ref="tableTitle">
    <!-- 操作 start -->
    <section class="operate-wrapper fixed-section">
      <!-- 左边操作的插槽 -->
      <!-- <div class="title-type">
        <slot name="operateRadioGroup"></slot>
      </div> -->

      <!-- 操作的插槽 -->
      <div class="operate-action">
        <slot name="operateAction"></slot>
        <i
          class="e6-icon-set-up_line"
          title="表格列设置"
          @click="handleShowColumn"
          v-show="canSetting"
        ></i>

        <i
          v-if="showSearch"
          class="e6-icon-search_line"
          title="搜索"
          @click="handleToggleSearchForm"
        ></i>
      </div>
      <div class="operate-action">
        <slot name="batchOperateAction"></slot>
      </div>
    </section>
    <!-- 操作 end -->
    <!-- 搜索表单的插槽 start -->
    <section
      class="search-wrapper fixed-section search-triangle"
      v-show="searchFormVisiable"
    >
      <!-- 搜索表单的插槽 -->
      <slot name="searchForm"></slot>
    </section>
    <!-- 搜索表单的插槽 end -->
    <column-setting-dialog
      :visible.sync="showSetting"
      @submit="submitSetting"
      :tableId="tableId"
    >
    </column-setting-dialog>
  </div>
</template>

<script>
import columnSettingDialog from "./columnSettingDialog/index.vue";
import { setSearch, setColumn } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "tableTitle",
  // mixins: [listPageReszie],
  data() {
    return {
      searchFormVisiable: true,
      showSetting: false
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    canSetting: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: Number
    },
    columnData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    columnSettingDialog
  },
  methods: {
    handleToggleSearchForm() {
      this.searchFormVisiable = !this.searchFormVisiable;
      this.$emit("resize");
    },

    // 显示配置弹框
    handleShowColumn() {
      this.showSetting = true;
    },
    //提交配置项
    submitSetting(showData, hiddenData, setType) {
      // showData：显示的表头/搜索条件字段数组集合
      //hiddenData：隐藏的表头/搜索条件字段数组集合
      let columnIdList = showData.map(item => {
        return item.columnId;
      });
      if (setType == 1) {
        //设置列
        this.setColumnReq(columnIdList);
      } else {
        this.setSearchReq(columnIdList);
      }
    },
    //设置搜索条件
    async setSearchReq(columnIdList) {
      try {
        this.dialogLoading = true;
        let res = await setSearch({
          tableId: this.tableId,
          columnIdList,
          versionNumber: 0
        });
        if (res.code == "OK") {
          this.$message.success("设置成功");
          this.showSetting = false;
          this.$emit("updateData");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //设置列
    async setColumnReq(columnIdList) {
      try {
        this.dialogLoading = true;
        let res = await setColumn({
          tableId: this.tableId,
          columnIdList,
          versionNumber: 0
        });
        if (res.code == "OK") {
          this.$message.success("设置成功");
          this.showSetting = false;
          this.$emit("updateData");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-triangle {
  position: relative;
  // &::before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 0px;
  //   height: 0px;
  //   border-style: solid;
  //   border-color: #ebeef5 transparent transparent transparent;
  //   border-width: 8px 8px 0px 8px;
  //   bottom: -7px;
  //   right: 16px;
  // }
  // &::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 0px;
  //   height: 0px;
  //   border-style: solid;
  //   border-color: #f9fafc transparent transparent transparent;
  //   border-width: 6px 6px 0px 6px;
  //   bottom: -5px;
  //   right: 18px;
  // }
}
</style>
