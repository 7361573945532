/*
 * @Description: 入口文件
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2020-12-31 10:32:53
 * @LastEditTime: 2023-03-10 16:54:51
 */

import Vue from "vue";
import App from "./App.vue";
import router from "e6-base-core/router"; // 路由
import store from "e6-base-core/store"; // vuex
import newE6VrSelect from "@/components/ui/vr-select";
import InputSelect from "@/components/ui/input-select";

import "e6-base-core";
import "@/assets/styles/index.scss";
import "@/icons";
// eslint-disable-next-line
import { has } from "@/directives/btnPermission";
// eslint-disable-next-line
import xlsx from "xlsx";
import "@/directives/dialogDrag";
/* 新加内容 start */
import Element from "element-ui";
import E6yunUI from "e6yun-ui";
import "e6yun-ui/lib/theme-chalk/index.css";
import "./permission";
import city from "@/components/city/city";
import remoteSearch from "@/components/remoteSearch/remoteSearch.vue";
import remoteSelectItem from "@/components/remoteSelectItem.vue";
Vue.component("remote-search-item", remoteSelectItem);
Vue.component("remote-search", remoteSearch);
import * as tools from "@/utils/tools";
Vue.prototype.$tools = tools;
Vue.use(Element, { size: "mini" });
Vue.use(E6yunUI);
Vue.use(newE6VrSelect);
Vue.use(InputSelect);
/* 新加内容 end */
Vue.config.productionTip = false;
Vue.component("city", city);
/**复制插件 */
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);
// const VUE_APP_VERSION = require("../package.json").version;
// const vers = window.localStorage.getItem("appVersion");
// if (VUE_APP_VERSION != vers) {
//   localStorage.clear();
//   window.localStorage.setItem("appVersion", VUE_APP_VERSION);
//   location.reload();
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
