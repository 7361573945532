/*
 * @Description:
 * @Author: sunxiaodong
 * @Date: 2020-09-24 18:51:31
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-04-12 14:27:49
 */
import InputSelect from "./src/main.vue";

InputSelect.install = function(Vue) {
  Vue.component(InputSelect.name, InputSelect);
};

export default InputSelect;
