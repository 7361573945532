/*
 * @Description: 公共请求接口
 * @Author: ChenXueLin
 * @Date: 2021-09-27 16:51:29
 * @LastEditTime: 2023-02-06 10:46:49
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_BASIC = "",
  E3_BL_CORPORATION = "",
  E3_BL_EWOS = "",
  E3_BASE_EXTAPI = "",
  E3_BASE_FILE_UPLOAD = ""
} = e3Api;

// 获取文件下载列表
export function getExportFileList(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/getExportList`,
    method: "post",
    data
  });
}

// 设置跟进时间
export function addFollowDate(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/addFollowDate`,
    method: "post",
    data
  });
}
// 添加服务详情
export function addServer(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/addServer`,
    method: "post",
    data
  });
}
//枚举接口
export function findDownList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/dictionary/listDown`,
    method: "post",
    data
  });
}
//获取客户属性
export function findCorpAttr(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/downList`,
    method: "post",
    data
  });
}
//获取全部员工
export function findAllUser(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/allIdAndNames`,
    method: "post",
    data
  });
}
//当前登陆用户主体下(所管辖员工)下拉列表
export function findAllUserRelation(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/allIdAndNamesRelation`,
    method: "post",
    data
  });
}
//根据角色组获取用户
export function getListIdAndNames(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/listIdAndNames`,
    method: "post",
    data
  });
}
// 查询该项目下所有的商品分类
export function getAllEquipClassList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getAllEquipClassList`,
    method: "post",
    data
  });
}

// 获取地区下拉选择框
export function getAreaDown(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/listSelect`,
    method: "post",
    data
  });
}
// 查询联系人下拉框
export function getContactList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/listByCorpId`,
    method: "post",
    data
  });
}
// 查询工程组长下拉框
export function getListLeader(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/listLeader`,
    method: "post",
    data
  });
}
//查询工程师下拉框数据
export function getEngineer(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/listEngineer`,
    method: "post",
    data
  });
}
// 查询所有的订单
export function getAllOrderList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getAllOrderList`,
    method: "post",
    data
  });
}
// 查询操作记录列表
export function getOprList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getOprList`,
    method: "post",
    data
  });
}
// 查询服务列表
export function getServerList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getServerList`,
    method: "post",
    data
  });
}
// 获取省市区县列表
export function getProviceList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/area/listByCode`,
    method: "post",
    data
  });
}
// 获取街道列表
export function getStreetList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/street/listByCode`,
    method: "post",
    data
  });
}
//获取商品分类
export function getFirstClassList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/equip/classify/listEquipFirstClass`,
    method: "post",
    data
  });
}
//获取商品开票名称
export function getSecondClassList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/equip/classify/listSecondByFirstId`,
    method: "post",
    data
  });
}
//获取商品名称(三级分类)
export function getThirdClassListBySecondClassId(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/equip/classify/listThirdBySecondClassId`,
    method: "post",
    data
  });
}
//获取商品名称(三级分类)
export function getThirdClassListByFirstClassId(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/equip/classify/listThirdByFirstClassId`,
    method: "post",
    data
  });
}
//获取表头接口
export function getHeaders(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/rpc/com.e6yun.project.e3.ewos.rpc.OperationLogServiceImpl/getHeaders/${data}`,
    method: "get"
  });
}
//获取日志列表
export function getLogList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/rpc/com.e6yun.project.e3.ewos.rpc.OperationLogServiceImpl/tCssWorkLogs`,
    method: "post",
    data
  });
}
//获取对比信息
export function getContrastInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/rpc/com.e6yun.project.e3.ewos.rpc.OperationLogServiceImpl/tCssWorkLogs`,
    method: "post",
    data
  });
}
//获取阿里云签名
export function getOssSign(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/getPostPolicy`,
    method: "post",
    data
  });
}
//获取华为云签名
export function getObsPostPolicy(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/getObsPostPolicy`,
    method: "post",
    data
  });
}
//上传华为云后获取id
export function saveFile(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/saveFile`,
    method: "post",
    data
  });
}
//上传到阿里云
export function uploadOss(headers, url, data) {
  return http({
    headers,
    url,
    method: "post",
    data
  });
}
//获取公司主体
export function getCompanyList(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI}/integration/getCompanyList`,
    method: "post",
    data
  });
}
