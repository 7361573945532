/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-07-26 13:35:35
 * @LastEditors: ChenXueLin
 */
export default {
  // 分页
  pageSize: 20, // 每页默认条数
  pageSizes: [10, 20, 50, 100, 200, 500], // 每页条数选择
  layout: "total, sizes, prev, pager, next, jumper", // 分页布局
  // loading
  loadingText: "拼命加载中", // loading 提示信息
  loadingSpinner: "el-icon-loading", // loading 加载图标
  loadingBackground: "rgba(255,255,255,0.5)", // loading 遮罩颜色

  // 常量 权限配置 类型按钮ID
  QUERY_BUTTON_ID: 69, // 查询
  ADD_BUTTON_ID: 70, // 新增
  EDIT_BUTTON_ID: 71, // 修改
  DELETE_BUTTON_ID: 72, // 删除
  APPROVE_BUTTON_ID: 73, // 审批
  EXPORT_BUTTON_ID: 105, // 导出Excel
  LOGOUT_BUTTON_ID: 81, // 注销
  DETAILS_BUTTON_ID: 300, // 详情
  IMPORT_BUTTON_ID: 301, // 导入
  BATCH_ADD_BUTTON_ID: 302, // 批量新增
  BATCH_DELETE_BUTTON_ID: 303, // 批量删除
  BATCH_EDIT_BUTTON_ID: 306, // 批量修改
  BATCH_ENABLE_BUTTON_ID: 307, // 批量启用
  BATCH_DISABLE_BUTTON_ID: 308, // 批量禁用
  HISTORY_RECORDS_BUTTON_ID: 304, // 日志(历史记录)
  SUBMIT_BUTTON_ID: 305, // 提交

  MAINTENANCE_COST_DISPATCHER_REVIEW_BUTTON_ID: 103, // 维护费用调度审核
  MAINTENANCE_COST_TREASURER_REVIEW_BUTTON_ID: 104, // 维护费用财务审核
  EDIT_SYSTEM_SCENE_NAME_BUTTON_ID: 106, // 修改系统车牌（系统场景名称）
  MAINTENANCE_CHANGE_SAME_CLASS_EQUIPMENT_BUTTON_ID: 107, // 维护更换同一级分类的设备

  MAINTENANCE_VEHICLE_NEED_NOT_DISPATCH_BUTTON_ID: 119, // 无需调度直接维护车辆

  EDIT_TASK_DISPATCH_BUTTON_ID: 120, // 更改任务派发
  EDIT_TASK_NEED_OR_NOT_RETURN_VISIT_BUTTON_ID: 121, // 更改是否需回访
  UNDEFINED_3_BUTTON_ID: 122, // 未使用动作3
  UNDEFINED_4_BUTTON_ID: 123, // 未使用动作4
  UNDEFINED_5_BUTTON_ID: 124, // 未使用动作5

  EDIT_AFTER_APPROVE_BUTTON_ID: 136, // 审批后修改
  EDIT_PHONE_EXT_BUTTON_ID: 137, // 修改分机号

  EDIT_CENTER_CODE_BUTTON_ID: 236, // 修改中心识别码
  // 客户相关数据源常量
  CORP_SOURCE_SYSTEM_E3: 0,
  CORP_SOURCE_SYSTEM_G7_CRM: 5
};
