/*
 * @Description: 消息中心
 * @Author: ChenXueLin
 * @Date: 2021-12-17 15:53:11
 * @LastEditTime: 2022-07-25 13:54:40
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_EWOS = "" // 8802
} = e3Api;
//查询维修工单质检列表
export function getRepairTaskInspectionPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/getRepairTaskInspectionPage`,
    method: "post",
    data
  });
}
//导出维修工单质检列表
export function exportRepairTaskInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/exportRepairTaskInspection`,
    method: "post",
    data
  });
}
//查询新装工单质检列表
export function getInstallTaskInspectionPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/getInstallTaskInspectionPage`,
    method: "post",
    data
  });
}
//导出新装工单质检列表
export function exportInstallTaskInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/exportInstallTaskInspection`,
    method: "post",
    data
  });
}
//复核通过工单质检
export function recheckTaskInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/recheckTaskInspection`,
    method: "post",
    data
  });
}
//提交工单
export function submitTaskInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/submitTaskInspection`,
    method: "post",
    data
  });
}
//开始质检
export function startQuality(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/startQuality`,
    method: "post",
    data
  });
}
//驳回工单质检
export function rejectTaskInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/rejectTaskInspection`,
    method: "post",
    data
  });
}
//查询故障信息
export function getFaultClassByParent(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/faultClass/getFaultClassByParent`,
    method: "post",
    data
  });
}
//查询质检记录
export function queryInspectionRecord(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/queryInspectionRecord`,
    method: "post",
    data
  });
}
//保存质检记录
export function saveInspectionRecord(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskInspection/saveInspectionRecord`,
    method: "post",
    data
  });
}
