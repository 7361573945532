import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const { host = "", E3_BL_EWOS = "", E3_BL_SUPPLIER = "" } = e3Api;

// 分页查询产品对应辅料列表
export function getProductAccessoryPageList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/getProductAccessoryPageList`,
    method: "post",
    data
  });
}
// 导出产品对应辅料列表
export function exportProductAccessory(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/exportProductAccessory`,
    method: "post",
    data
  });
}
// 批量启用禁用
export function batchEnableRelation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/batchEnableRelation`,
    method: "post",
    data
  });
}
// 批量启用禁用
export function unRelationProductAccessory(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/unRelation`,
    method: "post",
    data
  });
}
// 分页查询安装位置列表
export function getInstallProcess(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/installProcess/getPageList`,
    method: "post",
    data
  });
}
// 删除安装位置
export function deleteInstallProcess(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/installProcess/deleteInstallProcess`,
    method: "post",
    data
  });
}
// 新增/编辑安装位置
export function saveOrUpdate(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/installProcess/saveOrUpdate`,
    method: "post",
    data
  });
}
// 分页查询辅料列表
export function getAccessoryList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/accessory/listPage`,
    method: "post",
    data
  });
}
// 批量上架\下架\删除
export function batchUpdateStats(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/accessory/batchUpdateStats`,
    method: "post",
    data
  });
}
// 创建辅料
export function createAccessory(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/accessory/create`,
    method: "post",
    data
  });
}
// 修改辅料
export function updateAccessory(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/accessory/update`,
    method: "post",
    data
  });
}
// 获取没有维护辅料的产品列表
export function getProductAccessories(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/getProductList`,
    method: "post",
    data
  });
}
//获取安装位置下拉列表
export function getPosition(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/installProcess/getList`,
    method: "post",
    data
  });
}
//新增产品辅料关联关系
export function saveOrUpdateProductAccessory(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/saveOrUpdateProductAccessory`,
    method: "post",
    data
  });
}
//根据产品id，获取详情信息
export function getProductAccessoryDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productAccessories/getProductAccessoryDet`,
    method: "post",
    data
  });
}
//获取产品检测项分页列表
export function getProductInspectionPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/getProductInspectionPage`,
    method: "post",
    data
  });
}
//导出产品检测项分页列表
export function exportProductInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/exportProductInspection`,
    method: "post",
    data
  });
}
//删除产品对应的检测项
export function removeProductInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/removeProductInspection`,
    method: "post",
    data
  });
}
//检测项无需维护
export function unRelation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/unRelation`,
    method: "post",
    data
  });
}
//获取没有维护辅料的产品列表
export function getProductInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/getProductList`,
    method: "post",
    data
  });
}
//查询检测项配置下拉列表
export function getInspectionConfig(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/inspectionConfig/list`,
    method: "post",
    data
  });
}
//新增产品检测项配置
export function saveProductInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/saveProductInspection`,
    method: "post",
    data
  });
}
//启用或禁用产品检测项配置
export function enableProductInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/enableProductInspection`,
    method: "post",
    data
  });
}
//获取产品检测项详情
export function getProductInspectionDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/productInspection/getProductInspection`,
    method: "post",
    data
  });
}
//查询产品和物料信息
export function getRecyclableMaterialList(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/recyclableMaterial/listPage`,
    method: "post",
    data
  });
}
//新增产品和物料信息
export function saveRecyclableMaterial(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/recyclableMaterial/save`,
    method: "post",
    data
  });
}
//编辑产品和物料信息
export function updateRecyclableMaterial(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/recyclableMaterial/update`,
    method: "post",
    data
  });
}
//更新状态产品和物料信息
export function updateStatusRecyclableMaterial(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/recyclableMaterial/updateStatus`,
    method: "post",
    data
  });
}
