<!--
 * @Description: 修改密码
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2021-06-29 14:03:57
 * @LastEditTime: 2022-07-01 09:25:23
 * @FilePath: \e3-channel-client\src\@\layout\components\Navbar\changePassword.vue
-->
<template>
  <el-dialog
    v-dialogDrag
    width="450px"
    title="修改密码"
    append-to-body
    v-loading="loading"
    :visible.sync="visible"
    :show-close="showClose"
    custom-class="change-password-dialog"
    :before-close="handleCancel"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
  >
    <el-form ref="editForm" label-width="92px" :model="editForm" :rules="rules">
      <el-form-item v-if="needPasswordSimpleTips">
        <div class="edit-title">
          <span class="title-tip">
            <i class="iconfont e6-icon-abnormal_line"></i>
            密码过于简单，请修改您的密码！
          </span>
        </div>
      </el-form-item>
      <!--上次修改时间-->
      <!-- <span class="change-time">
        <i class="e6-icon-abnormal_line"></i>
        上次修改时间为：{{ lastPasswordModifiedTime }}
      </span> -->
      <!--原密码-->
      <el-form-item label="原密码" prop="oldPassword">
        <el-input v-bind="inputOptions" v-model="editForm.oldPassword" />
      </el-form-item>

      <!--新密码-->
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-bind="inputOptions" v-model="editForm.newPassword" />
      </el-form-item>
      <!--密码提示-->
      <el-form-item label=" ">
        <span class="msg"
          >密码长度6-15位，必须包含字母（区分大小写）、数字、字符中两种组成，不能使用空格、中文。</span
        >
      </el-form-item>
      <!--密码强度-->
      <el-form-item class="level-item" label="密码强度">
        <el-progress
          :percentage="pwdStrengthLevel.percent"
          :color="pwdStrengthLevel.color"
          :stroke-width="8"
          :show-text="false"
        />
        <span class="level-label">{{ pwdStrengthLevel.label }}</span>
      </el-form-item>
      <!--确认密码-->
      <el-form-item label="确认密码" prop="newPasswordConfirm">
        <el-input v-bind="inputOptions" v-model="editForm.newPasswordConfirm" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="handleCancel" v-if="showClose"
        >取 消</el-button
      >
      <el-button type="primary" @click="handleSubmit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
// interface
import { updatePassword } from "@/api";
// utils
import { mapGetters } from "vuex";

const MD5 = require("md5.js");

// mixins
import baseMixins from "@/mixins/base";
import { printError } from "@/utils/util";

// constant variables
const CN_REG = /[\u4E00-\u9FA5\uF900-\uFA2D]/; // 中文
const FULL_ANGLE = /[\uFF00-\uFFEF]/; // 全角符号
const SPACE = /(^\s+)|(\s+$)|\s+/g; // 空格
const PASSWORD_LEVEL_ENUM = [
  { percent: 0, level: "null", color: "#849bbd" },
  { percent: 30, level: "low", color: "#f45a5a", label: "低" },
  { percent: 60, level: "mid", color: "#e39726", label: "中" },
  { percent: 100, level: "high", color: "#56bb24", label: "高" }
];

export default {
  name: "changePassword",
  mixins: [baseMixins],

  data() {
    // 密码合法性校验
    const checkPwdValidator = (rule, value, cb) => {
      if (CN_REG.test(value)) {
        cb(new Error("密码不能包含中文字符"));
      } else if (FULL_ANGLE.test(value)) {
        cb(new Error("密码不能包含全角字符"));
      } else if (SPACE.test(value)) {
        cb(new Error("密码不能包含空格"));
      } else if (value.length < 6 || value.length > 15) {
        cb(new Error("新密码长度应在6~15位"));
      } else if (!["high", "mid"].includes(this.pwdStrengthLevel.level)) {
        cb(new Error("密码强度不符合要求"));
      } else {
        cb();
      }
    };
    const confirmPwdValidator = (rule, value, cb) => {
      if (value !== this.editForm.newPassword) {
        cb(new Error("两次输入密码不一致"));
      } else {
        cb();
      }
    };

    return {
      inputOptions: {
        autocomplete: "new-password",
        type: "password",
        maxlength: "15",
        placeholder: "请输入",
        clearable: true,
        showPassword: true
      },
      visible: false,
      editForm: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      rules: {
        oldPassword: "",
        newPassword: { validator: checkPwdValidator },
        newPasswordConfirm: { validator: confirmPwdValidator }
      }
    };
  },
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    needPasswordSimpleTips: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    // 密码等级
    pwdStrengthLevel() {
      const val = this.editForm.newPassword;
      if (
        !val ||
        val.length < 6 ||
        val.length > 15 ||
        CN_REG.test(val) ||
        FULL_ANGLE.test(val) ||
        SPACE.test(val)
      )
        return PASSWORD_LEVEL_ENUM[0];

      let isNum = /\d/.test(val); // 纯数字
      let isLetter = /[a-zA-Z]+/.test(val); // 字母
      let isSpecial = /[^a-zA-Z0-9]+/.test(val); // 除过数字和字母

      if (isNum && isLetter && isSpecial) return PASSWORD_LEVEL_ENUM[3];
      else if (
        (isNum && isLetter) ||
        (isNum && isSpecial) ||
        (isLetter && isSpecial)
      )
        return PASSWORD_LEVEL_ENUM[2];
      else return PASSWORD_LEVEL_ENUM[1];
    },
    lastPasswordModifiedTime() {
      // 上次修改时间
      return moment(this.userInfo.lastPasswordModifiedTime).format(
        "YYYY-MM-DD HH:mm:SS"
      );
    }
  },

  methods: {
    show() {
      this.visible = true;
    },

    handleCancel() {
      this.editForm = {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: ""
      };
      this.visible = false;
    },

    handleSubmit() {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          try {
            this.loading = true;
            const editForm = this.editForm;
            const res = await updatePassword({
              oldPassword: new MD5().update(editForm.oldPassword).digest("hex"),
              newPassword: new MD5().update(editForm.newPassword).digest("hex"),
              newPasswordConfirm: new MD5()
                .update(editForm.newPasswordConfirm)
                .digest("hex")
            });

            if (res.code === "OK") {
              this.$message.success(res.msg);
              this.handleCancel();
              this.$emit("updatePwd");
              Cookies.remove("needEditPwd");
            }
          } catch (e) {
            printError(e);
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.change-password-dialog {
  .el-dialog__body {
    min-height: 190px;
    padding: 20px 30px 2px !important;
  }
  .edit-title {
    padding-left: 0;
    border: none;
    background: none;
    text-align: center;
    .title-tip {
      font-size: 14px;
      font-weight: normal;
      color: #909399;
    }
  }
  .el-form-item__content {
    display: flex;
    width: 255px;
    min-height: 28px;
    align-items: center;

    .el-progress {
      flex: 1;
    }

    .level-label {
      font-size: 12px;
      margin-left: 5px;
      color: #898c91;
    }

    .msg {
      font-size: 12px;
      line-height: 1.2;
      color: #898c91;
    }
  }

  .change-time {
    display: block;
    margin-bottom: 5px;
    padding-left: 75px;
    color: #e39726;

    .e6-icon-abnormal_line {
      color: #898c91;
    }
  }
}
</style>
