var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"e6-vr-select el-select vr-select",on:{"click":_vm.handleToggleMenu}},[_c('el-input',{ref:"reference",attrs:{"type":"text","title":_vm.selectText,"disabled":_vm.disabled,"placeholder":_vm.placeholder},nativeOn:{"mouseenter":function($event){_vm.inputHovering = true},"mouseleave":function($event){_vm.inputHovering = false}},model:{value:(_vm.selectText),callback:function ($$v) {_vm.selectText=$$v},expression:"selectText"}},[(_vm.$slots.prefix)?_c('template',{slot:"prefix"},[_vm._t("prefix")],2):_vm._e(),_vm._v(" "),_c('template',{slot:"suffix"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showClose),expression:"!showClose"}],class:[
          'el-select__caret',
          'el-input__icon',
          'el-icon-' + _vm.iconClass
        ]}),_vm._v(" "),(_vm.showClose)?_c('i',{staticClass:"el-select__caret el-input__icon el-icon-circle-close",on:{"click":_vm.handleClearClick}}):_vm._e()])],2),_vm._v(" "),_c('transition',{attrs:{"name":"el-zoom-in-top"},on:{"after-leave":_vm.doDestroy}},[_c('e6-select-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"popper",class:['e6-vr-select-dropdown', _vm.popperClass],attrs:{"append-to-body":_vm.popperAppendToBody,"placement":_vm.placement,"inputWidth":_vm.inputWidth}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"e6-vr-select-dropdown__content",attrs:{"element-loading-text":_vm.loadingText,"element-loading-spinner":"el-icon-loading"}},[_c('el-checkbox-group',{model:{value:(_vm.checkedValue),callback:function ($$v) {_vm.checkedValue=$$v},expression:"checkedValue"}},[_c('e6-recycle-scroller',{directives:[{name:"show",rawName:"v-show",value:(_vm.showData.length),expression:"showData.length"}],staticClass:"vr-scroller",style:({ height: _vm.showData.length > 9 ? '220px' : 'auto' }),attrs:{"items":_vm.showData,"item-size":22,"buffer":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [[_c('div',{key:item.id,staticClass:"vr-scroller__item el-select-dropdown__item",class:{
                  selected: _vm.checkedValue === item.id,
                  'is-disabled': item.disabled
                },attrs:{"title":_vm.isTitle ? item.label : ''},on:{"click":function($event){return _vm.handleChange($event, item)}}},[_c('span',[_vm._v(_vm._s(item.label))])])]]}}])}),_vm._v(" "),(!_vm.showData.length)?[(_vm.$slots.empty)?_vm._t("empty"):_c('p',{staticClass:"el-select-dropdown__empty"},[_vm._v("\n              "+_vm._s(this.t("e6.vrSelect.noData"))+"\n            ")])]:_vm._e()],2)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }