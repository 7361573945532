import app, { namespace as appNamespace } from "@/store/modules/app";
import tagsView, {
  namespace as tagsViewNamespace
} from "@/store/modules/tagsView";
import permission, {
  namespace as permissionNamespace
} from "@/store/modules/permission";

import userInfo, {
  namespace as userInfoNamespace
} from "@/store/modules/userInfo";

import appGetters from "@/store/getters";

export const getters = {
  ...appGetters
};

export default {
  [appNamespace]: app,
  [tagsViewNamespace]: tagsView,
  [permissionNamespace]: permission,
  [userInfoNamespace]: userInfo
};
