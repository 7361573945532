<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-08-08 11:08:06
 * @LastEditTime: 2022-08-10 15:45:29
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="表头设置"
    :visible.sync="dialogVisible"
    width="640px"
    :close-on-click-modal="false"
    :before-close="handleCancel"
    custom-class="e6-set-column-or-search"
  >
    <div class="dialog-wrapper">
      <el-radio-group class="setting-type" v-model="value">
        <el-radio-button label="1">表格列</el-radio-button>
        <el-radio-button label="2">查询条件</el-radio-button>
      </el-radio-group>
      <div class="setting-content">
        <!-- 列设置 -->
        <div v-if="value == 1">
          <div class="e6-column-set__content" :style="{ height: height }">
            <div class="e6-column-set__hidden">
              <div class="e6-column-set__title">隐藏字段</div>
              <draggable
                class="e6-column-set__draggable"
                element="div"
                v-model="hideColumn"
                :move="onMove"
                v-bind="dragOptions()"
              >
                <transition-group
                  name="no"
                  class="e6-column-set__group"
                  tag="ul"
                >
                  <li
                    v-for="(element, index) in hideColumn"
                    :title="element.fieldLabel"
                    :class="[
                      'e6-column-set__item',
                      { undraggable: element.fixable }
                    ]"
                    :key="'hide' + index"
                  >
                    {{ element.fieldLabel }}
                  </li>
                </transition-group>
              </draggable>
            </div>
            <div class="e6-column-set__show">
              <div class="e6-column-set__title">显示字段</div>
              <draggable
                class="e6-column-set__draggable"
                element="div"
                v-model="showColumn"
                :move="onMove"
                v-bind="dragOptions()"
              >
                <transition-group
                  name="no"
                  class="e6-column-set__group"
                  tag="ul"
                >
                  <li
                    v-for="(element, index) in showColumn"
                    :title="element.fieldLabel"
                    :class="[
                      'e6-column-set__item',
                      { undraggable: element.fixable }
                    ]"
                    :key="'show' + index"
                  >
                    {{ element.fieldLabel }}
                  </li>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="e6-column-set__tips">
            <i class="e6-icon-tips_line"></i>{{ t("e6.columnSet.tip") }}
          </div>
        </div>
        <!-- 搜索条件设置 -->
        <div v-if="value == 2">
          <div class="search-wrapper">
            <h4>请勾选需要显示的查询条件</h4>
            <ul class="checkbox-group">
              <li
                class="checkbox-item"
                v-for="item in searchList"
                :key="item.columnId"
              >
                <el-checkbox :label="item.fieldName" v-model="item.checked">{{
                  item.fieldLabel
                }}</el-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button @click="handleCancel">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import index from "./index";
import draggable from "vuedraggable";
export default {
  name: "",
  components: { draggable },
  data() {
    return {
      value: "1",
      checkList: [],
      searchList: [],
      hideColumn: [],
      column: [],
      showColumn: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    tableId: {
      type: Number
    },
    height: {
      type: String,
      default: "320px"
    }
  },
  mixins: [index],
  computed: {},
  watch: {
    visible: {
      handler: function(val) {
        if (val) this.initData();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleCancel() {
      this.updatevisible(false);
      this.$emit("cancel", ...this.formartColumn());
      this.init();
    },
    handleSubmit() {
      this.$emit("submit", ...this.formartColumn());
      // this.init();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
/deep/.e6-set-column-or-search {
  position: relative;
  .setting-type {
    position: absolute;
    left: 0;
    top: 39px;
    bottom: 0;
    width: 130px;
    border-right: 1px solid #edf0f5;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-size: 0;
    .el-radio-button {
      display: block;
      .el-radio-button__inner {
        display: block;
        height: 40px;
        line-height: 40px;
        padding: 0;
        border: 0;
        border-left: 4px solid #fff;
        border-radius: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: left;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #fff;
        text-indent: 1.5em;
      }
    }
    .is-active {
      .el-radio-button__inner {
        color: #46bfea;
        border-left-color: #46bfea;
        background-color: #f5f7fa;
      }
    }
  }
  .setting-content {
    margin-left: 130px;
    height: 410px;
    .search-wrapper {
      height: 360px;
      h4 {
        margin-left: 16px;
        color: #48494c;
        line-height: 46px;
      }
      .checkbox-group {
        height: 320px;
        overflow: auto;
        margin: 0 14px;
        .checkbox-item {
          float: left;
          width: 50%;
          line-height: 30px;
        }
        .el-checkbox__label {
          color: #48494c;
        }
      }
    }
  }
  .dialog-footer {
    margin-top: 12px;
    text-align: center;
    .el-button--default {
      color: #909399;
      border-color: #dae1eb;
      background-color: #dae1eb;
    }
  }
}
</style>
