/*
 * @Description: 控制页面按钮权限--自定义指令
 * @Author: ChenXueLin
 * @Date: 2021-08-06 16:32:58
 * @LastEditTime: 2021-09-14 12:01:00
 * @LastEditors: ChenXueLin
 */

import Vue from "vue";
const has = Vue.directive("has", {
  inserted(el, binding, vnode) {
    const { value } = binding;
    const operationIds = vnode.context.$route.meta.operationIds || []; //获取mate中的权限
    if (value) {
      const hasPermission = operationIds.includes(value);
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`您没有操作权限"`);
    }
  }
});
export default { has };
