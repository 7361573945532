/*
 * @Description: 调拨管理
 * @Author: ChenXueLin
 * @Date: 2021-11-02 10:25:10
 * @LastEditTime: 2023-02-15 14:47:29
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const { host = "", E3_BL_SUPPLIER = "" } = e3Api;
//分页查询工程调拨单列表
export function queryEngineeringAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/listPage`,
    method: "post",
    data
  });
}
//新增工程调拨申请单
export function saveEngineeringAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/save`,
    method: "post",
    data
  });
}
//审核工程调拨申请单
export function examineEngineeringAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/examine`,
    method: "post",
    data
  });
}
//查询工程调拨单详情
export function getEngineeringApplyDetail(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/detail`,
    method: "post",
    data
  });
}
//分页查询调拨单列表
export function getAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/listPage`,
    method: "post",
    data
  });
}
//根据主体查询可使用仓库列表
export function queryStockWarehouse(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/stockManagement/queryStockWarehouse`,
    method: "post",
    data
  });
}
//查询可使用主体
export function queryStockOrganization(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/stockManagement/queryStockOrganization`,
    method: "post",
    data
  });
}
//新增调拨申请单
export function saveAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/save`,
    method: "post",
    data
  });
}
//查询调拨单详情
export function getAllocationApplyDetail(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/detail`,
    method: "post",
    data
  });
}
//调拨申请审核
export function examineAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/examine`,
    method: "post",
    data
  });
}
//查询仓库列表
export function queryWarehouse(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/stockManagement/queryWarehouse`,
    method: "post",
    data
  });
}
//批量出库确认
export function batchOutWarehouseConfirm(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/out/batchOutWarehouseConfirm`,
    method: "post",
    data
  });
}
//删除调拨申请
export function deleteAllocationApply(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/deleteAllocationApply`,
    method: "post",
    data
  });
}
//根据调拨单ID查询物料
export function getAllocationListByApplyId(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/allocationListByApplyId`,
    method: "post",
    data
  });
}
//分拣出库申请单详情
export function getApplyDetail(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/out/getApplyDetail`,
    method: "post",
    data
  });
}
//入库申请明细【弹窗】
export function getInWarehouseApplyDetail(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/inWarehouseApply/getInWarehouseApplyDetail`,
    method: "post",
    data
  });
}
//确认入库(多个)
export function batchInWarehouseConfirm(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/inWarehouseApply/batchInWarehouseConfirm`,
    method: "post",
    data
  });
}
//确认入库
export function inWarehouseConfirm(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/inWarehouseApply/inWarehouseConfirm`,
    method: "post",
    data
  });
}
//单个出库接口
export function outWarehouseConfirm(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/out/outWarehouseConfirm`,
    method: "post",
    data
  });
}
//获取物料列表
export function queryMaterialList(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/goodManagement/queryMaterialList`,
    method: "post",
    data
  });
}
//查询工程师/工程师仓名下物料
export function queryEnginerStockList(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/engineeringAllocationApply/queryEnginerStockList`,
    method: "post",
    data
  });
}
//根据组织+仓库查询大于0的物料列表
export function queryMaterialListByStock(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/getBaseInfo/materialListByStock`,
    method: "post",
    data
  });
}
//销审
export function revocationCheck(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/allocationApply/revocationCheck`,
    method: "post",
    data
  });
}
