/*
 * @Description: 导入中心
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2021-03-18 19:43:15
 * @LastEditTime: 2021-11-30 13:51:26
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_FILE_UPLOAD = "" // 8802
} = e3Api;

// 获取上传中心模块初始化数据
export function getUploadCenterInitInfo(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/getTemplate`,
    method: "post",
    data
  });
}
// 导入解析数据
export function importAnalysisResult(url, data) {
  return http({
    // url: `${host}${E3_BASE_FILE_UPLOAD}/${url}`,
    url: `${host}${url}`,
    method: "post",
    data
  });
}
// 下载文件
export function downFile(data) {
  return http({
    url: `${host}${E3_BASE_FILE_UPLOAD}/file/getUrl`,
    method: "post",
    data
  });
}
// 订单导入模板获取
export function getExportTemplate(data) {
  return http({
    headers: { "Content-Type": "application/json" },
    responseType: "blob",
    url: `${host}${E3_BASE_FILE_UPLOAD}/tms/excel/template/exportExcel`,
    method: "post",
    data
  });
}
