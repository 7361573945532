/**
 * @description: 格式化地址传参
 * @param { String } search
 * @return { Object }
 */
export function queryString(search) {
  return search
    ? search
        .slice(1)
        .split("&")
        .map(s => {
          const exec = /^(\w+)=(.{0,})/.exec(s);
          return exec ? exec.slice(1, 3) : [];
        })
        .reduce((p, xs) => ((p[xs[0]] = xs[1]), p), {})
    : {};
}

/**
 * @description: 检测是否是对象
 * @param { Object } v
 * @return { Boolean }
 */
export function isObject(v) {
  return Object.prototype.toString.call(v) === "[object Object]";
}

/**
 * @description: 在数组对象中，根据对象的fieldName字段，查找该对象display属性值
 * @param { String } fieldName
 * @param { Array } array
 * @return { Boolean }
 */
export function displaySearch(fieldName, array) {
  let obj = array.find(item => item.fieldName === fieldName);
  return obj ? obj.display : false;
}

/**
 * @description: 统一输出错误
 * @param { String } error
 * @return { }
 */
export function printError(error) {
  console.error(error);
}

/**
 * @desc 时间选择的框的快捷选项
 */
function generateShortcuts(val, include, type) {
  let unit = "";
  let unitText = "";
  let current = "";
  let last = "";
  let next = "";
  if (type === "day") {
    unit = "days";
    unitText = "天";
    current = "今天";
    last = "昨天";
    next = "明天";
  } else if (type === "month") {
    unit = "months";
    unitText = "月";
    current = "本月";
    last = "上月";
    next = "下月";
  } else {
    unit = "years";
    unitText = "年";
    current = "今年";
    last = "去年";
    next = "明年";
  }
  return val.map(item => {
    let text = "";
    let start = "";
    let end = "";
    // -1 代表 昨天|上个月|去年
    // 1 代表 明天|下个月|明年
    // 0 代表 今天|本月|今年
    if (item === -1) {
      text = last;
      start = moment()
        .startOf(type)
        .add(item, unit);
      end = moment()
        .endOf(type)
        .add(item, unit);
    } else if (item === 0) {
      text = current;
      start = moment().startOf(type);
      end = moment().endOf(type);
    } else if (item === 1) {
      text = next;
      start = moment()
        .startOf(type)
        .add(item, unit);
      end = moment()
        .endOf(type)
        .add(item, unit);
    }

    if (include) {
      if (item < -1) {
        text = `最近${Math.abs(item)}${unitText}`;
        start = moment()
          .startOf(type)
          .add(item + 1, unit);
        end = moment().endOf(type);
      } else if (item > 1) {
        text = `未来${Math.abs(item)}${unitText}`;
        start = moment().startOf(type);
        end = moment()
          .endOf(type)
          .add(item - 1, unit);
      }
    } else {
      if (item < -1) {
        text = `过去${Math.abs(item)}${unitText}`;
        start = moment()
          .startOf(type)
          .add(item, unit);
        end = moment()
          .endOf(type)
          .add(-1, unit);
      } else if (item > 1) {
        text = `以后${Math.abs(item)}${unitText}`;
        start = moment()
          .startOf(type)
          .add(+1, unit);
        end = moment()
          .endOf(type)
          .add(item, unit);
      }
    }
    return {
      text,
      onClick(picker) {
        picker.$emit("pick", [start.valueOf(), end.valueOf()]);
      }
    };
  });
}

/**
 * @desc  时间范围
 * @param params
 * @param include 是否包含今天
 */
export function shortcuts(params, include = true) {
  let isObj = Object.prototype.toString.call(params) === "[object Object]";
  if (!isObj) {
    throw new Error("传入的参数类型必须为 Object");
  }
  let { d = [], m = [], y = [] } = params;
  return [
    ...generateShortcuts(d, include, "day"),
    ...generateShortcuts(m, include, "month"),
    ...generateShortcuts(y, include, "year")
  ];
}

/**
 * @description: 检索取值
 * @param { Object } res 要检索的对象
 * @param { Array|string } path  要获取的对象路径
 * @param { * } defaultValue 如果解析值是 undefined，这值会被返回,默认值 []
 * @param { Boolean } freeze 是否冻结返回的数据 默认值true
 * @return { * }
 */

export function get(object, path, defaultValue, freeze) {
  let val = _.get(object, path, defaultValue);

  if (freeze === true) val = Object.freeze(val);

  return val;
}

/**
 * @desc  导出 Xlsx 文件 —— 兼容IE11+
 * @param response // 后台返回的数据
 */
export function exportXlsx(response) {
  const { headers = {}, data } = response;

  const ctd = "content-disposition";
  const ctdUpper = "Content-Disposition";

  let fileName = ""; //下载文件名称

  // 判断该接口是普通接口，还是数据导出接口
  if (
    (headers[ctdUpper] && headers[ctdUpper].indexOf("attachment") > -1) ||
    (headers[ctd] && headers[ctd].indexOf("attachment") > -1)
  ) {
    // 根据response header 中的 "content-disposition" 来获取导出文件名称
    fileName = (
      headers["content-disposition"] || headers["Content-Disposition"]
    ).split("=")[1];
    // fileName 中包含汉子，需要转义
    fileName = window.decodeURI(fileName);

    const blob = new Blob([data]);

    if (window.navigator.msSaveBlob) {
      // 兼容IE11+
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // 其他浏览器下载
      const eLink = document.createElement("a");
      eLink.download = fileName;
      eLink.style.display = "none";
      eLink.href = URL.createObjectURL(blob);
      document.body.appendChild(eLink);
      eLink.click();
      URL.revokeObjectURL(eLink.href); // 释放URL 对象
      document.body.removeChild(eLink);
    }
  } else {
    throw new Error(
      "导出方法 response 的 header 中没有 content-disposition 属性"
    );
  }
}

// 类型判断
export function typeOf(obj) {
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "Boolean",
    "[object Number]": "Number",
    "[object String]": "String",
    "[object Function]": "Function",
    "[object Array]": "Array",
    "[object Date]": "Date",
    "[object RegExp]": "RegExp",
    "[object Undefined]": "Undefined",
    "[object Null]": "Null",
    "[object Object]": "Object"
  };
  return map[toString.call(obj)];
}

/**
 * @desc  使用场景:表格搜索条件被配置隐藏，就要动态的去除入参中的该搜索字段；
 *        对象根据配置取值/剔除 => 后台显/隐字段
 *        默认配置是将入参对象剔除后台设置隐藏的字段，函数调用只需要前两个参数
 * @param searchParamsObj:Object 要操作的对象
 * @param disabledArray:Array  要取值/剔除的key字段数组
 * @param config:Object 配置项【默认使用功能为过滤后台隐藏字段】
 */
export function paramsFilterHandle(
  searchParamsObj,
  disabledArray,
  { displayName = "display", fieldName = "fieldName", rules = "omit" } = {}
) {
  if (!_.isPlainObject(searchParamsObj) || !_.isArray(disabledArray)) {
    throw new Error(
      "过滤函数中第一个参数请传入要处理的参数【对象】，第二个参数请传入配置对象【数组】"
    );
  }
  const omitOrPick = rules === "omit" ? "omit" : "pick";
  const displayFlag = rules === "pick"; // 根据pick和omit区分判断条件
  const disableKeys = disabledArray
    .filter(e => e[displayName] === displayFlag)
    .map(e => e[fieldName]);
  // 返回摘取或剔除后的对象
  const result = _[omitOrPick](searchParamsObj, disableKeys);
  return result;
}

/**
 * @desc  打开浏览器新标签页
 * @param searchParamsObj:String 跳转的路由地址
 * @param disabledArray:Object  query传值对象
 */
import VueRouter from "vue-router";
export function openBlankTab(path, queryObj) {
  let routeData = new VueRouter().resolve({
    path,
    query: {
      ...queryObj
    }
  });
  window.open(routeData.href, "_blank");
}
/*****部分下拉框需要id和name，统一处理*******/

export function filterSelectData(data = [], id) {
  if (data.length) {
    data.map(item => {
      if (id == item.id) {
        return item;
      }
    });
  }
}

/**
 * @desc 格式化时间
 * @param {string} fmt 时间格式 如: "YYYY/mm/dd HH:MM:SS"
 * @param {Date obeject} 时间对象
 * @return {string} 格式化后的时间字符串
 */
export function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
export function formatDate(numb) {
  let time = new Date((numb - 25569) * 86400 * 1000);
  // const time = new Date((numb - 1) * 24 * 3600000 + 1);
  // console.log(a, time, "~~~");
  // time.setYear(time.getFullYear() - 70);
  const year = time.getFullYear() + "";
  const month = time.getMonth() + 1 + "";
  let date = time.getDate() + "";
  // console.log(year, month, "@@@@@");
  // if (!leapyear(year) && year !== "2023" && year !== "2025") {
  //   //2023年比较特殊
  //   //如果是平年 20231227
  //   date = time.getDate() - 1 + "";
  // }
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date)
  );
}
//区分闰年与平年   最开始没区分  出现了4/0这种情况  日期少了一天
// const leapyear = year => {
//   var flag = false;
//   if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
//     flag = true;
//   }
//   return flag;
// };
/**
 * @desc 获取router params 参数中某个key值(保证直接刷新页面时能获取到 localStorege 里面记录的值)
 * @param {vueComponent} vm vue组件 有用到base mixin 的基础方法getHasParamsRoute 使用此方法需要引入base mixin
 * @param {string} key 对应字段名
 */
export function getKeyValueFromRouterParams(vm, key) {
  if (vm.$route.params[key]) {
    return vm.$route.params[key];
  }
  let hasParamsRoute = vm.getHasParamsRoute();
  return hasParamsRoute.params[key];
}
/**
 * @desc 获取当前月第一天第一秒到最后一天最后1秒时间戳
 * @return {[startTimestamp, endTimestamp]} 时间戳数组
 */
export function getMothRangeTimestamp() {
  let time = dateFormat("YYYY/mm/dd", new Date());
  time = time.split("/");
  time = time.map(item => item * 1);
  return [
    new Date(time[0], time[1] - 1, 1).getTime(),
    new Date(time[0], time[1], 0, 23, 59, 59).getTime()
  ];
}

/**
 * @desc 初始化组织架构数据(固定级别单选)
 * @param {array[object]} frameworkTreeData 数据列表
 * @param {number} targetLevel 目标组织级别 默认3
 * @param {number} level 当前初始化的等级 默认0
 * @return {array[{node}]} 返回一个符合 e6-select 使用的 树形结构数据
 * */
export function getRadioSelectTreeEnum(
  frameworkTreeData = [],
  targetLevel = 3,
  level = 0
) {
  if (!frameworkTreeData || !frameworkTreeData.length) {
    return [];
  }
  let frameworkTreeEnum = frameworkTreeData.map(node => {
    return {
      label: node.name,
      id: node.id,
      pid: node.pid,
      disabled: node.children.length && level < targetLevel,
      notAllowed: level !== targetLevel,
      children:
        level < targetLevel
          ? getRadioSelectTreeEnum(node.children, targetLevel, level + 1)
          : []
    };
  });
  return frameworkTreeEnum;
}
