<!--
 * @Description: 地址选择器
 * @Author: ChenXueLin
 * @Date: 2021-12-14 11:34:14
 * @LastEditTime: 2023-02-15 10:31:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="city-div" v-clickoutside="blur" :style="{ width: width }">
    <!-- 页面显示部分 -->
    <div class="city-input" :style="{ width: width }">
      <!-- 标签显示 -->
      <div class="input-name"></div>
      <!-- 输入框 -->
      <div
        ref="cityInput"
        class="search-input-main"
        :class="{ 'is-disabled': disabled, 'is-clearable': clearable }"
      >
        <input
          type="text"
          v-model="cityInputValue"
          readonly="true"
          class="search-input search-text fl"
          placeholder="请选择地址"
          @click.stop.prevent="showCityList"
        />
        <i
          class="drop select e6-icon-arrow-b_line"
          :class="{ dropUp: showCity }"
          @click.stop.prevent="showCityList"
        ></i>
        <i
          class="el-select__caret el-input__icon el-icon-circle-close"
          @click="clearCity"
        ></i>
      </div>
    </div>
    <!--选择层-->
    <transition name="zoom-in-top" :style="{ width: width }">
      <div
        class="city-select-list"
        v-show="showCity"
        ref="citySel"
        :class="{
          'city-select-list-dropUp': direct,
          'city-select-list-dropDown': !direct
        }"
      >
        <!-- 省市区街道头部tab切换 -->
        <div class="select-tab">
          <div
            v-for="(tab, index) in city_format"
            :key="index"
            class="tab-item"
            :class="{ 'tab-active': curtab == tab.name }"
            :style="{ width: (1 / city_format.length) * 100 + '%' }"
            @click.stop.prevent="tabClick(tab.name)"
          >
            {{ tab.name }}
          </div>
        </div>
        <!-- 内容展示区 -->
        <div
          style="height: 100%; width: 100%; overflow: hidden;"
          v-loading="cityLoading"
          :element-loading-background="loadingBackground"
        >
          <div class="select-text" @click.stop.prevent>
            <transition-group
              enter-active-class="animated faster slideInRight"
              leave-active-class="animated faster slideOutLeft"
              :duration="{ enter: 500, leave: 1 }"
              tag="div"
            >
              <div v-show="curtab == '省'" :key="'省'">
                <ul>
                  <li
                    v-for="pro in provinceList"
                    :key="pro.id"
                    @click.stop.prevent="proClick(pro)"
                  >
                    {{ pro.name }}
                  </li>
                </ul>
              </div>
              <div v-show="curtab == '市'" :key="'市'">
                <ul>
                  <li
                    v-for="city in cityList"
                    :key="city.id"
                    :title="city.name"
                    @click.stop.prevent="cityClick(city)"
                  >
                    {{ city.name }}
                  </li>
                </ul>
                <div class="no-data" v-if="!cityList.length">
                  请选择省
                </div>
              </div>
              <div v-show="curtab == '区县'" :key="'区县'">
                <ul>
                  <li
                    v-for="district in districtList"
                    :key="district.id"
                    :title="district.name"
                    @click.stop.prevent="districtClick(district)"
                  >
                    {{ district.name }}
                  </li>
                </ul>
                <div class="no-data" v-if="!districtList.length">
                  请选择市
                </div>
              </div>
              <!-- 街道 -->
              <div v-show="curtab == '街道'" :key="'街道'">
                <ul>
                  <li
                    v-for="street in streetList"
                    :key="street.id"
                    :title="street.name"
                    @click.stop.prevent="streetClick(street)"
                  >
                    {{ street.name }}
                  </li>
                </ul>
                <div class="no-data" v-if="!streetList.length">
                  请选择区
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <div class="option empty"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import Clickoutside from "./clickoutside";
import { getProviceList, getStreetList } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
export default {
  name: "selectCity",
  components: {},
  data() {
    return {
      curtab: "省", //默认tab为省
      direct: true, // true向上展开   false 向下展开\
      provinceList: [], //省列表
      cityList: [], //市列表
      districtList: [], //区列表
      streetList: [], //街道列表
      format: this.showStreet
        ? ["省", "市", "区县", "街道"]
        : ["省", "市", "区县"],
      showCity: false,
      city_format: this.showStreet
        ? [
            {
              id: 1,
              name: "省"
            },
            {
              id: 2,
              name: "市"
            },
            {
              id: 3,
              name: "区县"
            },
            {
              id: 4,
              name: "街道"
            }
          ]
        : [
            {
              id: 1,
              name: "省"
            },
            {
              id: 2,
              name: "市"
            },
            {
              id: 3,
              name: "区县"
            }
          ],
      pro: {
        code: "",
        id: "",
        name: "",
        shortName: ""
      }, //选中的省份基本信息
      city: {
        code: "",
        id: "",
        name: "",
        shortName: ""
      }, //选中的市基本信息
      district: {
        code: "",
        id: "",
        name: "",
        shortName: ""
      }, //选中的区基本信息
      street: {
        code: "",
        id: "",
        name: "",
        shortName: ""
      } //选中的街道基本信息
    };
  },
  props: {
    width: {
      type: String,
      default: "300px"
    }, // 默认宽度
    defaultCity: {
      type: String,
      default: ""
    },
    streetDisabled: {
      type: Boolean,
      default: false
    },
    showStreet: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    Clickoutside
  },
  mixins: [base],
  computed: {
    cityInputValue() {
      let value;
      if (this.defaultCity) {
        value = this.defaultCity;
      } else {
        value =
          this.pro.name +
          this.city.name +
          this.district.name +
          this.street.name;
      }
      return value;
    }
  },
  watch: {},
  methods: {
    // 浏览器高度变化处理函数
    handleResize() {
      this.$nextTick(() => {
        this.windowHeight = document.documentElement.clientHeight;
        // 然后获取div到浏览器窗口顶部的高度
        var divHeight = this.$refs.cityInput.getBoundingClientRect().top;
        // this.windowHeight浏览器窗口的高度
        // 下拉列表的高度 300px
        // 当windowHeight大于等于divHeight与scrollH的和的时候向下展开
        this.windowHeight = document.documentElement.clientHeight;
        this.heightStyle = this.$refs.citySel.clientHeight;
        if (this.windowHeight >= divHeight + this.heightStyle) {
          this.direct = false;
        } else {
          this.direct = true;
          this.$refs.citySel.style.marginTop = "0px";
        }
      });
    },
    // 清空
    clearCity() {
      this.curtab = "省";
      this.pro = {
        code: "",
        id: "",
        name: "",
        shortName: ""
      };
      this.city = {
        code: "",
        id: "",
        name: "",
        shortName: ""
      };
      this.district = {
        code: "",
        id: "",
        name: "",
        shortName: ""
      };
      this.street = {
        code: "",
        id: "",
        name: "",
        shortName: ""
      };
      this.cityList = this.districtList = this.streetList = [];
      this.$emit("update:defaultCity", "");
      this.callBack();
    },
    //获取下拉框数据
    async queryData(type, code) {
      try {
        this.cityLoading = true;
        let res = await getProviceList({ type, code });
        switch (type) {
          case "-65536":
            this.provinceList = res.data;
            break;
          case "-256":
            this.cityList = res.data;
            break;
          case "-1":
            this.districtList = res.data;
            if (!this.districtList.length && this.showStreet) {
              this.curtab = "街道";
              this.getStreetList(code);
            }
            break;
          default:
            break;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.cityLoading = false;
      }
    },
    //获取街道下拉框
    async getStreetList(code) {
      try {
        this.cityLoading = true;
        let res = await getStreetList({ code });
        this.streetList = res.data;
        if (!this.streetList.length) {
          this.blur();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.cityLoading = false;
      }
    },
    // 点击省份
    proClick(pro) {
      if (pro.name !== this.pro.name) {
        this.city = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.district = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.street = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.cityList = []; //市列表
        this.districtList = []; //区列表
        this.streetList = []; //街道列表
        this.$emit("update:defaultCity", "");
      }
      this.pro.code = pro.code;
      this.pro.id = pro.id;
      this.pro.name = pro.name;
      this.pro.shortName = pro.shortName;
      this.curtab = "市";
      this.queryData("-256", pro.code);
    },
    // 点击城市
    cityClick(city) {
      if (city.name != this.city.name) {
        this.district = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.street = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.districtList = []; //区列表
        this.streetList = []; //街道列表
        this.$emit("update:defaultCity", "");
      }
      this.city.code = city.code;
      this.city.id = city.id;
      this.city.name = city.name;
      this.city.shortName = city.shortName;
      this.curtab = "区县";
      this.queryData("-1", city.code);
    },
    // 区县点击
    districtClick(district) {
      console.log(district, this.district, "===");
      if (district.name != this.district.name) {
        this.street = {
          code: "",
          id: "",
          name: "",
          shortName: ""
        };
        this.streetList = []; //街道列表
        this.$emit("update:defaultCity", "");
      }
      this.district.code = district.code;
      this.district.id = district.id;
      this.district.name = district.name;
      this.district.shortName = district.shortName;
      console.log(this.streetDisabled, "streetDisabled==");
      if (this.streetDisabled) {
        this.blur();
      } else {
        this.curtab = "街道";
        this.getStreetList(district.code);
      }
    },
    //点击街道
    streetClick(street) {
      this.street.code = street.code;
      this.street.id = street.id;
      this.street.name = street.name;
      this.street.shortName = street.shortName;
      this.$emit("update:defaultCity", "");
      this.blur();
      // this.clearCity();
    },
    //控制内容页的展开折叠
    blur() {
      if (this.showCity) {
        this.showCity = false;
        // if (!this.defaultCity) {
        this.callBack();
        // }
      }
    },
    //选中值格式处理
    callBack() {
      this.$emit("selected", {
        province: this.pro,
        city: this.city,
        district: this.district,
        street: this.street,
        name:
          this.pro.name + this.city.name + this.district.name + this.street.name
      });
    },
    //点击输入框
    showCityList() {
      // 组件是否禁用
      document.body.click();
      if (this.disabled) {
        return;
      }
      if (!this.showCity) {
        this.showCity = true;
      } else {
        this.blur();
      }
      this.handleResize();
    },
    // tab切换点击
    tabClick(val) {
      this.curtab = val;
      this.handleResize();
      this.queryData("-65536", "");
    }
  },
  created() {
    this.queryData("-65536", "");
  }
};
</script>
<style lang="scss" scoped>
@charset "UTF-8";
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  clear: both;
}
::-webkit-input-placeholder {
  color: #c0c4cc;
}
:-moz-placeholder {
  color: #c0c4cc;
}
::-moz-placeholder {
  color: #c0c4cc;
}
:-ms-input-placeholder {
  color: #c0c4cc;
}
.zoom-in-top-enter-active,
.zoom-in-top-leave-active {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform-origin: center top;
  transform-origin: center top;
}
.zoom-in-top-enter,
.zoom-in-top-leave-active {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}
.slide-fade-x-enter-active {
  -webkit-transition: all 0.1s ease 0.1s;
  transition: all 0.1s ease 0.1s;
}
.slide-fade-x-enter {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-y-enter-active {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slide-fade-y-enter,
.slide-fade-y-leave-to {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.faster {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
.city-div {
  font-size: 12px;
  position: relative;
  color: #666;
  top: 0;
}
.city-div .base-mask {
  width: 100%;
  height: 28px;
  background-color: #e0e0e0;
  z-index: 2;
  position: absolute;
  border-radius: 4px;
  opacity: 0.6;
}
.city-div .is-disabled {
  .search-input {
    background-color: #fafafa;
    border-color: #e0e0e0;
    color: #666;
    cursor: not-allowed !important;
  }
  .drop {
    display: inline-block !important;
    cursor: not-allowed !important;
  }
  .el-icon-circle-close {
    display: none !important;
  }
}
.city-div .city-input {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 28px;
  line-height: 28px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  cursor: pointer;
}
.city-div .city-input .search-input-main {
  width: 100%;
  .el-icon-circle-close {
    display: none;
    &::before {
      height: 28px;
      line-height: 28px;
      vertical-align: top;
      color: #c0c4cc;
      display: inline-block;
    }
  }
}
.city-div .city-input .is-disabled .search-text {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
.city-div .city-input .is-clearable {
  &:hover {
    .el-icon-circle-close {
      display: inline-block;
    }
    .drop {
      display: none;
    }
  }
}
.city-div .city-input .search-input-main .search-input {
  border-radius: 4px;
}
.city-div .city-input .drop {
  height: 24px;
  font-style: normal;
  font-size: 8px;
  color: #909399;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 24px;
  text-align: center;
  display: inline-block;
}
.city-div .city-input .dropUp {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.city-div .city-input .search-text {
  cursor: pointer;
  color: #666;
  font-size: 12px;
  height: 100%;
  width: calc(100% - 26px);
  text-indent: 10px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.city-div .city-input .search-text:focus {
  outline: 0;
}
.city-div .city-select-list-dropUp {
  bottom: 30px;
}
.city-div .city-select-list-dropDown {
  top: 30px;
}
.city-div .city-select-list {
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 9999;
  max-height: 400px;
  width: 360px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.city-div .city-select-list .is-horizontal {
  display: none;
}
.city-div .city-select-list .city-select-list-main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.city-div .city-select-list .city-select-item,
.city-div .city-select-list .city-select-noItem {
  width: auto;
  position: relative;
  padding-left: 10px;
  height: 34px;
  line-height: 34px;
  cursor: default;
  white-space: nowrap;
}
.city-div .city-select-list .city-select-item:hover {
  color: #35aae6;
  background-color: #fafafa;
  cursor: pointer;
}
.city-div .city-select-list .active {
  color: #35aae6;
  font-weight: 700;
}
.city-div .city-select-list .select-tab {
  width: 100%;
  height: 36px;
  line-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.city-div .city-select-list .select-tab .tab-item {
  height: 100%;
  text-align: center;
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.city-div .city-select-list .select-tab .tab-item:hover {
  color: #35aae6;
  background-color: #fafafa;
  cursor: pointer;
}
.city-div .city-select-list .select-tab .tab-item:nth-child(1) {
  border-left: none;
}
.city-div .city-select-list .select-tab .tab-active {
  background-color: #fff;
  color: #35aae6;
  border-bottom: none;
}
.city-div .city-select-list .select-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 250px;
}
.city-div .city-select-list .select-text ul {
  padding: 0;
}
.city-div .city-select-list .select-text li {
  list-style: none;
  float: left;
  height: 30px;
  width: 17%;
  line-height: 30px;
  margin: 0 5px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.city-div .city-select-list .select-text li:hover {
  color: #35aae6;
  background-color: #fafafa;
  cursor: pointer;
}
.city-div .city-select-list .select-text li.common {
  width: 45%;
}
.city-div .city-select-list .select-text .no-data {
  margin-top: 15px;
  color: #aaa;
  text-align: center;
}
.city-div .city-select-list .option {
  height: 30px;
  line-height: 30px;
  width: 100%;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.city-div .city-select-list .option a {
  width: 50px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}
.city-div .city-select-list .option .add {
  background-color: #35aae6;
  color: #fff;
  border-radius: 4px;
  margin: auto 0;
}
.city-div .city-select-list .option .clear {
  color: #35aae6;
  position: absolute;
  right: 0;
  line-height: 30px;
}
.city-div .city-select-list .empty {
  height: 10px;
}
.el-form-item.is-error .city-input {
  border-color: #f56c6c;
}
</style>
