/*
 * @Description: 回访单管理
 * @Author: ChenXueLin
 * @Date: 2021-11-02 10:25:10
 * @LastEditTime: 2022-04-27 16:10:22
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const { host = "", E3_BL_EWOS = "", E3_BL_CALLCENTER = "" } = e3Api;

//获取回访服务单列表
export function getVisiteTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/listReviews`,
    method: "post",
    data
  });
}
//获取回访服务单基本信息
export function getReviewInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/reviewInfo`,
    method: "post",
    data
  });
}
//获取客户回访服务单详情任务项分页列表
export function getReviewService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/review/detail/itemPage`,
    method: "post",
    data
  });
}
//指派处理人
export function handler(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/review/transfer`,
    method: "post",
    data
  });
}
//变更联系人
export function changeContact(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/review/changeContact`,
    method: "post",
    data
  });
}
//查看回访结果
export function getVisiteResult(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/revisit/question/list`,
    method: "post",
    data
  });
}
//获取问题列表
export function getListQuestions(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/getQuestionList`,
    method: "post",
    data
  });
}
//提交回访
export function submitQuestion(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/revisit/update/revisitResult`,
    method: "post",
    data
  });
}
//创建回访服务单
export function createReviewService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/review/save`,
    method: "post",
    data
  });
}
