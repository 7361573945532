/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2022-08-02 13:41:34
 * @LastEditTime: 2022-08-10 11:55:27
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const { host = "", E3_BASE_BASIC = "" } = e3Api;
//分页获取列表
export function getConfigTable(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configTable/listPage`,
    method: "post",
    data
  });
}
//新增列表
export function createTable(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configTable/create`,
    method: "post",
    data
  });
}
//修改列表
export function updateTable(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configTable/update`,
    method: "post",
    data
  });
}
//分页获取列
export function getConfigcolumn(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configcolumn/listPage`,
    method: "post",
    data
  });
}
//删除列配置
export function deleteConfigcolumn(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configcolumn/delete`,
    method: "post",
    data
  });
}
//修改列配置
export function updateConfigcolumn(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configcolumn/update`,
    method: "post",
    data
  });
}
//查询列
export function getColumn(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configcolumn/getAllColumn`,
    method: "post",
    data
  });
}
//设置列
export function setColumn(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/configcolumn/set`,
    method: "post",
    data
  });
}
//分页获取搜索条件
export function getAllSearch(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/table/search/getAllSearch`,
    method: "post",
    data
  });
}
//添加搜索条件
export function addSearch(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/table/search/batchJsonAdd`,
    method: "post",
    data
  });
}
//设置搜索条件
export function setSearch(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/table/search/set`,
    method: "post",
    data
  });
}
//查询搜索条件
export function getSearch(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/table/search/getSearch`,
    method: "post",
    data
  });
}
//删除搜索条件
export function deleteSearch(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/table/search/delete`,
    method: "post",
    data
  });
}
