/*
 * @Description: 当前账号用户信息
 * @Author: LiangYiNing
 * @Date: 2022-03-10 14:27:25
 * @LastEditTime: 2022-06-22 15:13:45
 * @LastEditors: ChenXueLin
 */

import { getBasic } from "@/api";

const state = {
  userId: "",
  userName: "",
  // userCode: "",
  // userCorp: "",
  // userCorpId: "",
  // userDuty: "",
  // userDutyId: "",
  // userFramework: "",
  // userFrameworkId: "",
  // userPost: "",
  // userPostId: "",
  // userRole: [],
  // userRoleId: [],
  // userStatus: "",
  // userType: "",
  employeeId: ""
  // phone: "",
  // phoneExt: "",
  // versionNumber: ""
};

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    for (let key in state) {
      if (key === "userRole" || key === "userRoleId") {
        state[key] = userInfo[key] || [];
      } else {
        state[key] = userInfo[key];
      }
    }
  }
};

const actions = {
  async setUserInfo({ commit }) {
    let res = await getBasic({ id: "" });
    if (res.code === "OK") {
      commit("SET_USER_INFO", res.data);
    } else {
      commit("SET_USER_INFO", {});
    }
  }
};

export const namespace = "userInfo";

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
