/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-07-27 09:50:03
 * @LastEditors: ChenXueLin
 */
import { namespace as appNamespace } from "./modules/app";
import { namespace as tagsViewNamespace } from "./modules/tagsView";
import { namespace as permissionNamespace } from "./modules/permission";
import { namespace as userInfoNamespace } from "./modules/userInfo";

export default {
  sidebar: state => state[appNamespace].sidebar,
  isScreenfull: state => state[appNamespace].isScreenfull,
  visitedViews: state => state[tagsViewNamespace].visitedViews,
  cachedViews: state => state[tagsViewNamespace].cachedViews,

  menu: state => state[permissionNamespace].menu,
  allMenu: state => state[permissionNamespace].allMenu,
  addRoutes: state => state[permissionNamespace].addRoutes,
  operationIds: state => state[permissionNamespace].operationIds,

  volumeUnit: state => state[appNamespace].volumeUnit,
  weightUnit: state => state[appNamespace].weightUnit,

  userInfo: state => state[userInfoNamespace].userInfo
};
