<!--
 * @Description: 左侧菜单显示隐藏切换
 * @Author: sunxiaodong
 * @Date: 2020-04-09 14:46:53
 * @LastEditors: sunxiaodong
 * @LastEditTime: 2020-07-16 16:54:54
 -->
<template>
  <div class="hamburger" @click="handleToggleSideBar">
    <i :class="iconClass"></i>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["sidebar"]),

    iconClass() {
      return `e6-icon-menu_${!this.sidebar.opened ? "open" : "off"}`;
    }
  },

  methods: {
    ...mapActions("app", ["toggleSideBar"]),

    handleToggleSideBar() {
      this.toggleSideBar();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    cursor: pointer;
    i {
      font-size: 22px;
      color: #79889a;
    }
  }
}
</style>
