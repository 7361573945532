/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2023-03-10 16:53:17
 * @LastEditors: ChenXueLin
 */
// 暂时用不上
export const e6BaseCarrier3Api = {
  host: "",
  E6_MS_TMS_PERSONALIZE_WEB: "/E6-MS-TMS-PERSONALIZE-WEB",
  E6_MS_TMS_E6YUN_BASE_WEB: "/E6-MS-TMS-E6YUN-BASE-WEB",
  E3_BASE_LOGIN: "/COMMON-MODULE-MENU-WEB",
  E6_MS_TMS_COMMON_OPERATION_LOG_WEB: "/E6-MS-TMS-COMMON-OPERATION-LOG-WEB",
  E3_BL_EWOS: "/api/EBOSS-SERVER-BASICS-DATA" // 8802
};

/* e3 */
export const e3Api = {
  host: "",
  E3_BASE_EXTAPI: "/api/E3-BASE-EXTAPI", //第三方
  E3_BASE_EXTAPI_TIANRUN: "/api/E3-BASE-EXTAPI-TIANRUN", //客服相关
  E3_AG: "/api/E3-AG",
  E3_BASE_BASIC: "/api/E3-BASE-BASIC", //基本信息
  E3_BASE_FILE_UPLOAD: "/api/E3-BASE-FILE-UPLOAD", // 文件上传
  E3_BASE_LOGIN: "/api/E3-BASE-LOGIN", // 登录
  E3_BASE_LOGIN_LJY: "/api/E3-BASE-LOGIN-LJY", //LDAP账号登录
  E3_BASE_USER_SYSTEM: "/api/E3-BASE-USER-SYSTEM", //  用户，权限相关
  E3_BL_CALLCENTER: "/api/E3-BL-CALLCENTER", //  客服中心
  E3_BL_CORPORATION: "/api/E3-BL-CORPORATION", //  公司相关业务服务
  E3_BL_EWOS: "/api/E3-BL-EWOS", //  工单中心
  E3_BL_STE: "/api/E3-BL-STE", //场景、终端、设备 业务服务
  E3_BL_SUPPLIER: "/api/E3-BL-SUPPLIER"
};

export default [];
