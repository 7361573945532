/*
 * @Description: 消息中心
 * @Author: ChenXueLin
 * @Date: 2021-12-17 15:53:11
 * @LastEditTime: 2022-07-25 13:54:40
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_BASIC = "" // 8802
} = e3Api;
//分页查询我的消息列表
export function getMessageList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/message/listPage`,
    method: "post",
    data
  });
}
//全部已读
export function readMessage(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/message/all/readMessage`,
    method: "post",
    data
  });
}
//处理未读
export function handleUnread(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/message/readMessage`,
    method: "post",
    data
  });
}
//查询我的未读消息数量
export function getUnreadMessageCount(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/message/getUnreadMessageCount`,
    method: "post",
    data
  });
}
//分页查询审核列表
export function getApprovalList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/approval/fs/list`,
    method: "post",
    data
  });
}
//查询审批记录列表
export function getApprovalRecordList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/approvalNode/listByApprovalId`,
    method: "post",
    data
  });
}
