var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar"},[(!_vm.isScreenfull)?_c('Hamburger'):_vm._e(),_vm._v(" "),_c('div',{ref:"tagsWrapper",staticClass:"tags-wrapper",class:{ active: _vm.isMoveTag }},[_c('tags-view',{ref:"tagsView"}),_vm._v(" "),_c('div',{staticClass:"move-tag"},[_c('i',{staticClass:"previous e6-icon-arrow-l_line",attrs:{"title":"向前移动"},on:{"mousedown":function($event){$event.preventDefault();return _vm.handleMoveTagMousedown(-1)},"mouseup":function($event){$event.preventDefault();return _vm.handleMoveTagMouseup()}}}),_vm._v(" "),_c('i',{staticClass:"next e6-icon-arrow-r_line",attrs:{"title":"向后移动"},on:{"mousedown":function($event){$event.preventDefault();return _vm.handleMoveTagMousedown(1)},"mouseup":function($event){$event.preventDefault();return _vm.handleMoveTagMouseup()}}})])],1),_vm._v(" "),_c('div',{staticClass:"setting-wrapper"},[_c('span',{staticClass:"notice"},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"manual","popper-class":"popover-message"}},[_c('i',{class:[
            'alarm',
            'e6-icon-risk-alarm_line',
            { 'is-active': _vm.alarmAndMsgInfo.alarmCount }
          ],attrs:{"slot":"reference"},on:{"click":_vm.handleAudit},slot:"reference"},[_c('sup')])]),_vm._v(" "),_c('el-popover',{attrs:{"placement":"bottom","trigger":"manual","popper-class":"popover-message"},model:{value:(_vm.msgPopoverVisible),callback:function ($$v) {_vm.msgPopoverVisible=$$v},expression:"msgPopoverVisible"}},[_c('i',{class:[
            'message',
            'e6-icon-mail_line',
            { 'is-active': _vm.messageNum > 0 }
          ],attrs:{"slot":"reference"},on:{"click":_vm.handleTurnToMessageCenter},slot:"reference"},[_c('sup')])])],1),_vm._v(" "),(!_vm.isScreenfull)?_c('el-dropdown',{staticClass:"user",attrs:{"placement":"bottom"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('span',{staticClass:"user-name",attrs:{"title":_vm.userName}},[_vm._v(_vm._s(_vm.userName))]),_vm._v(" "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_vm._v(" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"changePassword"}},[_c('span',[_vm._v("修改密码")])]),_vm._v(" "),_c('el-dropdown-item',{attrs:{"command":"logout"}},[_c('span',[_vm._v("退出")])])],1)],1):_vm._e(),_vm._v(" "),_c('change-password',{ref:"changePasswordRef"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }