/*
 * @Description: 服务单管理
 * @Author: ChenXueLin
 * @Date: 2021-11-22 17:53:54
 * @LastEditTime: 2022-05-11 16:03:38
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_CALLCENTER = "",
  E3_BL_STE = "",
  E3_BL_EWOS = "" // 8802
} = e3Api;
// 查询服务单列表
export function getServiceList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/listServices`,
    method: "post",
    data
  });
}
//批量关闭服务单
export function closeService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/batchClose`,
    method: "post",
    data
  });
}
//批量关闭检验
export function closeConfirm(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/batchClose/confirm`,
    method: "post",
    data
  });
}
// 批量关闭服务单
export function closeServiceTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/reviewService/close`,
    method: "post",
    data
  });
}
// 批量转单
export function changeServiceTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/batchTransfer`,
    method: "post",
    data
  });
}
// 创建维修服务单
export function addRepairService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/repairService/create`,
    method: "post",
    data
  });
}
// 查询场景列表
export function getSceneNameList(data) {
  return http({
    url: `${host}${E3_BL_STE}/scene/listPage`,
    method: "post",
    data
  });
}
// 创建场景变更服务单
export function addChangeService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/changeService/create`,
    method: "post",
    data
  });
}
// 创建设置打印信息服务单
export function addPrintService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/printService/create`,
    method: "post",
    data
  });
}
// 查询服务单详情基本信息
export function getServiceBasicInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/basicInfo/get`,
    method: "post",
    data
  });
}
// 我来处理+转单
export function handleService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/service/handle`,
    method: "post",
    data
  });
}
// 查询维修服务单详情任务项分页列表
export function getRepairService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/repairService/listPage`,
    method: "post",
    data
  });
}
// 查询咨询回复服务单详情任务项分页列表
export function getReplyItems(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/replyService/itemPage`,
    method: "post",
    data
  });
}
// 下拉选择框选择回复时限
export function getLimitList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/time/limit/list`,
    method: "post",
    data
  });
}
// 创建咨询回复服务单
export function addReplyService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/replyService/create`,
    method: "post",
    data
  });
}
// 创建开通服务单
export function addOpenService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/openService/create`,
    method: "post",
    data
  });
}
// 创建报停服务单
export function addStopService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/stopService/create`,
    method: "post",
    data
  });
}
// 创建对接服务单
export function addDockService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/dockService/create`,
    method: "post",
    data
  });
}
// 处理维修服务单
export function handleRepairService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/repairService/handle`,
    method: "post",
    data
  });
}
// 获取场景变更任务项
export function getChangeService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/changeService/listPage`,
    method: "post",
    data
  });
}
// 变更处理请求
export function agreeOrDisAgree(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/changeService/agreeOrDisAgree`,
    method: "post",
    data
  });
}
// 获取开通任务项
export function getOpenService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/openService/listPage`,
    method: "post",
    data
  });
}
//同意开通+拒绝开通
export function agreeOpen(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/openService/agreeOrDisAgree`,
    method: "post",
    data
  });
}
// 获取报停任务项
export function getStopService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/stopService/listPage`,
    method: "post",
    data
  });
}
//同意报停+拒绝报停
export function agreeStop(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/stopService/agreeOrDisAgree`,
    method: "post",
    data
  });
}
// 获取设置打印任务项
export function getPrintService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/printService/listPage`,
    method: "post",
    data
  });
}
// 处理完成设置打印
export function finishPrint(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/printService/success`,
    method: "post",
    data
  });
}
// 拒绝设置打印
export function refusePrint(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/printService/refuse`,
    method: "post",
    data
  });
}
// 获取对接信息任务项
export function getDockService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/dockService/listPage`,
    method: "post",
    data
  });
}
// 对接完成+拒绝对接
export function handleDock(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/dockService/agreeOrDisAgree`,
    method: "post",
    data
  });
}
// 获取咨询回复服务单详情
export function getReplyService(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/replyService/basicInfo`,
    method: "post",
    data
  });
}
// 咨询回复已处理
export function handleReply(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/service/replyService/handle`,
    method: "post",
    data
  });
}

// 获取回访类型下拉框数据
export function getVisiteType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/config/list`,
    method: "post",
    data
  });
}
