/*
 * @Description: 客户管理
 * @Author: ChenXueLin
 * @Date: 2021-11-05 16:39:46
 * @LastEditTime: 2022-07-19 15:12:13
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_CORPORATION = "", //
  E3_BASE_EXTAPI_TIANRUN = ""
  // E3_BL_CORPORATION = "" // 8802
} = e3Api;

/**********客户列表***************/
// 获取客户列表
export function getCorpList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/listPage`,
    method: "post",
    data
  });
}
//批量删除客户
export function deleteCorpList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/batchDelete`,
    method: "post",
    data
  });
}
//导出客户
export function exportCorpList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/export`,
    method: "post",
    data
  });
}
// 新增客户
export function createCorp(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corp/create`,
    method: "post",
    data
  });
}
// 获取客户详情
export function getCorpDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corp/detail`,
    method: "post",
    data
  });
}
// 获取客户详情（新增后审批前）
export function getCorpDetailBefore(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corp/createApproveDetail`,
    method: "post",
    data
  });
}
// 获取客户详情（修改后审批前）
export function getUpdateCorpDetailBefore(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/detailBefore/update`,
    method: "post",
    data
  });
}
// 修改客户信息
export function updateCorp(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corp/update`,
    method: "post",
    data
  });
}

/**********客户列表***************/
// 获取开票信息列表
export function getInvoiceData(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/listPage`,
    method: "post",
    data
  });
}
// 获取开票信息下拉列表
export function getInvoiceDownList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/downList/db`,
    method: "post",
    data
  });
}
// 获取开票信息详情
export function getInvoiceDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/detail`,
    method: "post",
    data
  });
}
// 获取开票信息审批详情
export function getInvoiceDetailBefore(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/detailBefore`,
    method: "post",
    data
  });
}
// 新增开票信息
export function createInvoice(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/create`,
    method: "post",
    data
  });
}
// 编辑开票信息
export function updateInvoice(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/update`,
    method: "post",
    data
  });
}
//删除开票信息
export function deleteInvoiceData(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/batchDelete`,
    method: "post",
    data
  });
}
//导出开票信息
export function exportInvoiceData(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/export`,
    method: "post",
    data
  });
}
//获取开票名列表 （天眼查）
export function invoiceDataDownListFormTianyancha(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/invoiceData/downList`,
    method: "post",
    data
  });
}
/******客户属性********/
// 获取客户属性列表
export function getCorpAttrList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/listPage`,
    method: "post",
    data
  });
}
// 客户属性新增
export function createCorpAttr(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/create`,
    method: "post",
    data
  });
}
// 客户属性详情
export function getCorpAttrDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/detail`,
    method: "post",
    data
  });
}
// 客户属性批量删除
export function batchDeleteCorpAttr(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/batchDelete`,
    method: "post",
    data
  });
}
// 客户属性批量修改
export function updateCorpAttr(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/update`,
    method: "post",
    data
  });
}
// 客户属性导出
export function exportCorpAttr(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/export`,
    method: "post",
    data
  });
}
// 客户属性下拉列表
export function getCorpAttrDownList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpAttr/downList`,
    method: "post",
    data
  });
}
// 获取队列号
export function getQnoList(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI_TIANRUN}/record/get/qnoList`,
    method: "post",
    data
  });
}

/******主体信息********/

// 获取主体信息列表
export function getCompany(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/listPage`,
    method: "post",
    data
  });
}
// 获取主体信息下拉列表
export function getCompanydownList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/downList`,
    method: "post",
    data
  });
}
// 获取特殊的主体信息下拉列表
export function getCompanySpecialDownList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/specialDownList`,
    method: "post",
    data
  });
}
//删除主体信息
export function deleteCompany(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/batchDelete`,
    method: "post",
    data
  });
}
//导出主体信息
export function exportCompany(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/export`,
    method: "post",
    data
  });
}
//添加主体信息
export function createCompany(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/create`,
    method: "post",
    data
  });
}
//修改主体信息
export function updateCompany(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/company/update`,
    method: "post",
    data
  });
}

/******客户移交********/

// 获取客户移交列表
export function getcorpShift(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpShift/listPage`,
    method: "post",
    data
  });
}
// 获取客户移交导出
export function exportCustomerHandOver(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpShift/export`,
    method: "post",
    data
  });
}

// 获取客户移交列表
export function corpShiftDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpShift/get`,
    method: "post",
    data
  });
}
// 获取通过业务员客户移交列表
export function getCustomerListBySalesman(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/listPageByCorpName`,
    method: "post",
    data
  });
}
// 新增客户移交
export function createCorpShift(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpShift/create`,
    method: "post",
    data
  });
}

/******联系人管理*********/
export function getContact(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/listPage`,
    method: "post",
    data
  });
}
//新增联系人
export function createContact(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/create`,
    method: "post",
    data
  });
}
//联系人详情
export function getContactDetails(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/get`,
    method: "post",
    data
  });
}
//修改联系人
export function updateContact(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/update`,
    method: "post",
    data
  });
}
//批量删除联系人
export function batchDeleteContact(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/batchDelete`,
    method: "post",
    data
  });
}
//导出联系人
export function exportContact(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/export`,
    method: "post",
    data
  });
}
//联系人关系列表
export function contactRelationList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contactRelation/listPage`,
    method: "post",
    data
  });
}
//联系人关系删除
export function batchDeleteContactRelation(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contactRelation/batchDelete`,
    method: "post",
    data
  });
}
//联系人关系新增
export function createContactRelation(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contactRelation/create`,
    method: "post",
    data
  });
}
//客户常用地址列表
export function getCorpAddrListPage(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/corpAddrListPage`,
    method: "post",
    data
  });
}
//客户常用地址删除
export function corpAddrDelete(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/corpAddrBatchDelete`,
    method: "post",
    data
  });
}
//客户常用地址新增
export function corpAddrCreate(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/corpAddrCreate`,
    method: "post",
    data
  });
}
//客户常用地址修改
export function corpAddrUpdate(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/corpAddrUpdate`,
    method: "post",
    data
  });
}
