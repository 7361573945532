<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-08-19 09:18:28
 * @LastEditTime: 2023-02-28 17:06:02
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="logo-wrapper">
    <!-- <svg-icon iconClass="logo" class="logo"></svg-icon>
    <svg-icon iconClass="logoMini" class="logo-mini"></svg-icon> -->
    <img class="logo" src="~@/assets/images/Logo.png" alt="" />
    <img class="logo-mini" src="~@/assets/images/logo-mini.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .logo {
      display: none;
    }
    .logo-mini {
      display: block;
      width: 35px;
      // height: 50px;
    }
  }
  &.is-active,
  &.horizontal {
    .logo-wrapper {
      .logo {
        display: block;
        width: 120px;
        // height: 40px;
      }
      .logo-mini {
        display: none;
      }
    }
  }
}
</style>
