<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-10-26 16:49:26
 * @LastEditTime: 2023-02-20 16:21:39
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="menu-content">
    <div class="menu-item" @mouseleave="leave()" :class="appClass">
      <div
        :class="[menuIndex == index ? 'activeMenu' : '', 'menu-title']"
        v-for="(item, index) in menu"
        :key="index"
      >
        <div @mouseenter="ev => enter(ev, index)">
          <i :class="item.meta.icon"></i>
          <span v-if="sidebar.opened">{{ item.meta.title }}</span>
          <!-- <i class="el-icon-arrow-right"></i> -->
        </div>
      </div>
      <!-- 子菜单弹框 -->
      <div
        class="menu-dialog"
        :class="{
          'close-menu-dialog': !sidebar.opened
        }"
        ref="menuDialog"
        :style="{ top: top + 'px', left: showSubMenu ? '100%' : '-700%' }"
      >
        <div
          class="sub-menu-content"
          v-for="(subMenu, idx) in subMenuList"
          :key="idx"
        >
          <!-- 二级菜单 -->
          <a
            :href="subMenu.meta.url ? '#' + subMenu.meta.url : null"
            @click="clickSubMenu(subMenu.meta.url)"
          >
            <div>
              <div
                :class="[
                  subMenu.children.length ? '' : 'sub-menu-item-hover',
                  'sub-menu-item'
                ]"
              >
                <img
                  src="@/assets/images/menuIcon.png"
                  alt=""
                  class="menuIcon"
                  v-if="subMenu.children.length"
                />
                <div class="pot" v-else></div>
                <div class="sub-menu-title2">
                  {{ subMenu.meta.title }}
                </div>
              </div>
            </div>
          </a>
          <!-- 三级菜单 -->
          <div
            v-for="(subMenu3, idx) in subMenu.children"
            :key="idx"
            :class="[
              subMenu3.children.length ? '' : 'sub-menu-level3-1',
              'sub-menu-level3'
            ]"
          >
            <div v-if="subMenu3.children.length">
              <img src="@/assets/images/menuIcon.png" alt="" class="menuIcon" />
              <div class="sub-menu-title3">{{ subMenu3.meta.title }}</div>
            </div>
            <a
              :href="'#' + subMenu3.meta.url"
              v-else
              @click="clicksubMenu3(subMenu3.meta.url)"
            >
              <div class="pot"></div>
              <div class="sub-menu-title3">{{ subMenu3.meta.title }}</div>
            </a>
            <!--  -->
            <div
              v-for="(subMenu4, idx) in subMenu3.children"
              :key="idx"
              :class="['sub-menu-level3-1', 'sub-menu-level3']"
              style="margin-bottom:0px;margin-top:10px;margin-left:10px;"
            >
              <a :href="'#' + subMenu4.meta.url">
                <div class="pot"></div>
                <div class="sub-menu-title3">{{ subMenu4.meta.title }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </el-scrollbar> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Menu",
  data() {
    return {
      top: 0,
      subMenuList: [],
      showSubMenu: false,
      menuIndex: 0
    };
  },

  computed: {
    ...mapGetters(["sidebar", "menu", "cachedViews", "visitedViews"]),
    appClass() {
      return [!this.sidebar.opened ? "closeMenu" : ""];
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVla) {
        if (newVla) {
          this.getActiveMenu(this.menu);
        }
      }
    }
  },

  mounted() {
    this.subMenuList = this.menu[this.menuIndex].children;
  },

  methods: {
    enter(ev, index) {
      this.menuIndex = index;
      this.subMenuList = this.menu[index].children;
      this.$nextTick(() => {
        let top = index * 40;
        let evTop = ev.target.getBoundingClientRect().top;
        let menuDialogH = this.$refs.menuDialog.getBoundingClientRect().height;
        let documentBodyH = document.body.clientHeight;
        top =
          evTop + menuDialogH > documentBodyH
            ? top + documentBodyH - (evTop + menuDialogH)
            : top;
        this.top = top;
        this.showSubMenu = true;
      });
    },
    // clickMenu(index) {
    //   this.top = index * 40;
    //   this.menuIndex = index;
    //   this.subMenuList = this.menu[index].children;
    //   this.showSubMenu = true;
    // },
    //点击二级菜单
    clickSubMenu(url) {
      console.log(
        "二级菜单地址：",
        url,
        "visitedViews:",
        this.visitedViews,
        "cachedViews:",
        this.cachedViews
      );
    },
    //点击三级菜单
    clicksubMenu3(url) {
      console.log(
        "二级菜单地址：",
        url,
        "visitedViews:",
        this.visitedViews,
        "cachedViews:",
        this.cachedViews
      );
    },
    leave() {
      this.showSubMenu = false;
      this.getActiveMenu(this.menu);
    },
    getActiveMenu(list) {
      let pid = this.$route.meta.modulePid;
      let _this = this;
      for (let i = 0; i < list.length; i++) {
        let a = list[i];
        if (a.id == pid) {
          if (a.pid == 0) {
            this.menuIndex = this.menu.findIndex(element => {
              return element.id == a.id;
            });
          } else {
            this.menu.forEach((el, idx) => {
              if (el.children && el.children.length) {
                let flag = el.children.filter(v => {
                  return v.id == a.id;
                });
                if (flag.length) {
                  this.menuIndex = idx;
                }
              }
            });
          }
        } else {
          if (a.children && a.children.length > 0) {
            _this.getActiveMenu(a.children);
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-content {
  width: 100%;
  border: none;
  background-color: transparent;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  .menu-item {
    width: 200px;
    //height: 500px;
    position: relative;
    background-image: linear-gradient(#353e4c, #353e4c);
    border-bottom: 1px solid #313946;
  }
  .closeMenu {
    width: 50px;
  }
  .menu-title {
    color: rgba(255, 255, 255, 0.6);
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
    white-space: nowrap;
    list-style: none;
    position: relative;
    &:hover {
      // background-color: rgba(255, 255, 255, 0.1);
      background: #46bfea;
      color: #fff;
    }
    i {
      margin-right: 5px;
      color: inherit;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
    }
    .el-icon-arrow-right {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -7px;
      font-size: 12px;
    }
    & > div {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  .activeMenu {
    background: #46bfea;
    color: #fff;
  }
  .menu-dialog {
    position: absolute;
    z-index: 99999;
    top: 0px;
    left: -300%;
    width: calc(100% + 30px);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    // min-width: 240px;
    padding: 20px 20px 10px;
    background-color: rgba(53, 62, 76, 0.8);
    // box-sizing: border-box;
    .sub-menu-content {
      height: 100%;
      .sub-menu-item {
        height: 28px;
        padding: 10px;
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 10px;
        line-height: 8px;
        display: inline-block;
        .sub-menu-title2 {
          font-size: 14px;
          color: rgb(255, 255, 255);
          font-weight: bold;
          display: inline-block;
        }
      }
      .sub-menu-item-hover {
        &:hover {
          border-radius: 20px;
          background-color: rgb(70, 191, 234);
          .pot {
            background-color: #fff;
          }
        }
      }
      .menuIcon {
        width: 12px;
        height: 13px;
        position: relative;
        top: 2px;
        margin-right: 5px;
      }

      .sub-menu-level3 {
        margin-bottom: 10px;
        margin-left: 30px;
        display: inline-block;
        // padding: 5px 10px;
        box-sizing: border-box;
        .sub-menu-title3 {
          font-size: 13px;
          color: rgb(255, 255, 255);
          display: inline-block;
        }
      }
      .sub-menu-level3-1 {
        margin-bottom: 10px;
        margin-left: 20px;
        display: inline-block;
        padding: 5px 10px;
        box-sizing: border-box;

        &:hover {
          border-radius: 20px;
          background-color: rgb(70, 191, 234);
          .pot {
            background-color: #fff;
          }
        }
      }
    }
    .pot {
      background-color: rgb(190, 194, 200);
      width: 6px;
      height: 6px;
      margin-right: 5px;
      display: inline-block;
      position: relative;
      top: -2px;
    }
  }
  .close-menu-dialog {
    width: 240px;
  }
}
</style>
