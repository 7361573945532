import http from "e6-base-core/axios/config";
import { e6BaseCarrier3Api } from "@/config/api";
const { host, E6_MS_TMS_REPORT_WEB } = e6BaseCarrier3Api;

// 查询报警总数和消息的最近一条
export function fetchAlarmCountAndLastMsg() {
  return http({
    url: `${host}${E6_MS_TMS_REPORT_WEB}/alarmResult/getAlarmAndAbnormalCountNew`,
    method: "get"
  });
}

// 查询最近五条报警信息
export function fetchTopAlarmList() {
  return http({
    url: `${host}${E6_MS_TMS_REPORT_WEB}/alarmResult/getTopAlarmInfo`,
    method: "get"
  });
}

// 查询最近五条报警信息
export function batchIgnore(data) {
  return http({
    url: `${host}${E6_MS_TMS_REPORT_WEB}/alarmResult/akeyToIgnore`,
    method: "post",
    data
  });
}

//报警处理
export function saveAlarmDispose(data) {
  return http({
    url: `${host}${E6_MS_TMS_REPORT_WEB}/alarmResult/handlerAlarmResult`,
    method: "post",
    data
  });
}
