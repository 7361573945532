<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-05-13 18:04:18
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="container-wrapper">
    <!-- <Breadcrumb></Breadcrumb> -->
    <router-view v-if="$route.path.includes('redirect') && routerViewDispaly" />

    <keep-alive :include="cachedViews" v-else-if="routerViewDispaly">
      <router-view :key="key" />
    </keep-alive>
  </div>
</template>

<script>
// import Breadcrumb from "../Breadcrumb/index.vue"; Breadcrumb
import eventBus from "@/bus";

export default {
  name: "AppMain",
  data() {
    return {
      routerViewDispaly: true
    };
  },
  components: {},
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  },
  mounted() {
    eventBus.$on("refreshTab", this.refreshTab);
  },
  methods: {
    async refreshTab() {
      this.routerViewDispaly = false;
      await this.$nextTick();
      this.routerViewDispaly = true;
    }
  }
};
</script>
