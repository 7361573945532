<template>
  <div class="app-wrapper" :class="appClass">
    <div class="header-wrapper">
      <!-- <template v-if="layout === 'horizontal'">
        <div class="logo-container">
          <Logo />
        </div>
        <div class="menu-container">
          <Menu :mode="mode"> </Menu>
        </div>
        <div class="navbar-container">
          <Navbar />
        </div>
      </template> -->

      <template v-if="!isScreenfull">
        <div class="logo-container">
          <Logo />
        </div>
        <div class="navbar-container">
          <Navbar />
        </div>
        <div class="menu-container">
          <Menu :mode="mode"> </Menu>
        </div>
      </template>
      <!-- 弹屏样式 -->
      <template v-if="isScreenfull">
        <div class="navbar-container full-navbar-container ">
          <Navbar />
        </div>
      </template>
    </div>
    <div class="main-wrapper" :class="{ 'full-main-wrapper': isScreenfull }">
      <AppMain></AppMain>
    </div>
  </div>
</template>

<script>
import Logo from "./components/Logo/index.vue";
import Menu from "./components/Menu";
// import Menu from "./components/Menu/index.vue";
import Navbar from "./components/Navbar/index.vue";
import AppMain from "./components/AppMain/index.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Logo,
    Menu,
    Navbar,
    AppMain
  },
  computed: {
    ...mapGetters(["sidebar", "isScreenfull"]),

    layout() {
      return "vertical";
    },

    tagsView() {
      return true;
    },

    appClass() {
      return [
        this.layout,
        this.sidebar.opened && this.layout === "vertical" ? "is-active" : "",
        this.tagsView ? "is-tags-view" : ""
      ];
    },

    mode() {
      return this.layout === "horizontal" ? "horizontal" : "vertical";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.app-wrapper {
  background-color: $--color-theme-first-dark-4-5;
  background-color: $--color-theme-first-dark-5-5;
  background-color: $--color-theme-first-dark-6;
  background-color: $--color-theme-first-dark-6-3;
  background-color: $--color-theme-first-dark-6-5;
  background-color: $--color-theme-first-dark-7;

  height: 100%;
  .tags-view-wrapper {
    height: $tags-view-height;
  }
  &.horizontal {
    /deep/ .menu-container {
      background-color: transparent;
      .scrollbar-wrapper,
      .el-scrollbar__wrap,
      .el-scrollbar__view,
      .el-menu--horizontal,
      .menu-item {
        background-color: transparent !important;
      }
    }

    .header-wrapper {
      display: flex;
      align-items: center;
      height: $header-height--h;
      background-color: $header-background-color--h;
      background-image: $header-background-image--h;
      .logo-container {
        width: $logo-width--h;
        height: 100%;
        background-color: $logo-background-color--h;
      }

      .navbar-container {
        flex-grow: 1;
      }
    }
    .main-wrapper {
      height: calc(100% - #{$header-height--h});
      .main-container {
        height: 100%;
      }
    }
    &.is-tags-view {
      .main-wrapper {
        height: calc(100% - #{$header-height--h} - #{$tags-view-height});
      }
    }
  }

  &.vertical {
    position: relative;
    .header-wrapper {
      position: relative;
      display: flex;
      height: $header-height--v;
      // line-height: $header-height--v;
      background-color: $header-background-color--v;
      .logo-container {
        width: $logo-collapse-width--v;
        height: $logo-height--v;
        background-color: $logo-background-color--v;
      }
      .navbar-container {
        position: relative;
        width: calc(100% - #{$logo-collapse-width--v});
        height: $header-height--v;
        border-bottom: 1px solid #e8f0f8;
        // line-height: $header-height--v;
      }
      .full-navbar-container {
        width: 100%;
      }
    }

    .menu-container {
      position: absolute;
      top: $logo-height--v;
      left: 0;
      width: $menu-collapse-width--v;
      height: calc(100% - #{$logo-height--v});
      background-image: $menu-background-image--v;
    }

    .main-wrapper {
      height: calc(100% - #{$header-height--v});
      margin-left: $menu-collapse-width--v;
      background-color: $main-wrapper-background-color;
    }

    &.is-active {
      .header-wrapper {
        .logo-container {
          width: $logo-width--v;
        }
        .navbar-container {
          width: calc(100% - #{$logo-width--v});
        }
        .full-navbar-container {
          width: 100%;
        }
      }
      .menu-container {
        width: $menu-width--v;
      }
      .main-wrapper {
        margin-left: $menu-width--v;
      }
      .full-main-wrapper {
        margin-left: 0;
      }
      /deep/ {
        .menu-item {
          .is-opened {
            background-color: #313946 !important;
          }
          .el-submenu {
            &.is-active > .el-submenu__title {
              color: $--color-primary !important;
            }
          }
        }
      }
    }
  }
}
</style>
