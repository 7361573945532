/*
 * @Description: 列设置方法
 * @Author: LiangYiNing
 * @Date: 2021-12-17 14:53:55
 * @LastEditTime: 2022-08-10 13:44:22
 * @LastEditors: ChenXueLin
 */
import Locale from "e6yun-ui/src/mixins/locale";
const cloneDeep = require("lodash/cloneDeep");
import { getColumn, getSearch } from "@/api";
import { printError } from "@/utils/util";
export default {
  data() {
    return {};
  },
  mixins: [Locale],
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.updatevisible(v);
      }
    }
  },
  watch: {},
  methods: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        filter: ".undraggable"
      };
    },
    async initData() {
      try {
        let promiseList = [
          getColumn({ tableID: this.tableId }),
          getSearch({ tableId: this.tableId })
        ];
        let [columnRes, searchRes] = await Promise.all(promiseList);
        this.column = columnRes.data.configColumnList;
        this.column.map(item => {
          item.show = item.display == 1 ? true : false;
        });
        this.init();
        let searchList = searchRes.data;
        searchList.map(item => {
          this.$set(item, "checked", item.display == 1 ? true : false);
          return item;
        });
        this.searchList = searchList;
      } catch (error) {
        printError(error);
      }
    },
    init() {
      let hideColumn = [];
      let showColumn = [];
      this.column.forEach(item => {
        if (item.show) {
          showColumn.push(item);
        } else {
          hideColumn.push(item);
        }
      });
      this.hideColumn = cloneDeep(hideColumn);
      this.showColumn = cloneDeep(showColumn);
    },
    updatevisible(v) {
      this.$emit("update:visible", v);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },

    handleBeforeClose(done) {
      this.init();
      done();
    },
    formartColumn() {
      let showColumn = this.showColumn.map(item => {
        item.show = true;
        return item;
      });
      let hideColumn = this.hideColumn.map(item => {
        item.show = false;
        return item;
      });
      let showSearch = this.searchList.filter(item => {
        return item.checked;
      });
      let hideSearch = this.searchList.filter(item => {
        return !item.checked;
      });
      // 设置动态列
      if (this.value == 1) {
        return [showColumn, hideColumn, this.value];
      } else {
        //设置动态搜索条件
        return [showSearch, hideSearch, this.value];
      }
    }
  }
};
