/*
 * @Description: 运维中心
 * @Author: ChenXueLin
 * @Date: 2021-12-17 15:53:11
 * @LastEditTime: 2023-03-23 18:58:32
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  // E3_AG = "",
  E3_BL_EWOS = "" // 8802
} = e3Api;
//分页查询工程师列表
export function getEngineerList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/listPage`,
    method: "post",
    data
  });
}
//导出工程师列表
export function exportEngineerList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/listExport`,
    method: "post",
    data
  });
}
//获取工程师详情
export function getEngineerDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/detail`,
    method: "post",
    data
  });
}
//获取工程师详情
export function saveEngineerDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/save`,
    method: "post",
    data
  });
}
//分页查询调度员列表
export function getDispatcherList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/dispatcher/listPage`,
    method: "post",
    data
  });
}
//分页查询项目列表
export function getProjectList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/listPage`,
    method: "post",
    data
  });
}
//批量关闭项目
export function closeProject(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/batchClose`,
    method: "post",
    data
  });
}
//新增项目
export function createProject(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/create`,
    method: "post",
    data
  });
}
//获取项目基本信息
export function getProjectDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/get`,
    method: "post",
    data
  });
}
//获取关联任务单列表
export function getProjectTaskListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/getProjectItemListPage`,
    method: "post",
    data
  });
}
//获取关联任务单列表
export function getItemListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/getItemListPage`,
    method: "post",
    data
  });
}
//添加任务单
export function createProjectTaskRel(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/createProjectTaskRel`,
    method: "post",
    data
  });
}
//删除任务单
export function deleteProjectTaskRel(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/deleteProjectTaskRel`,
    method: "post",
    data
  });
}
//设置关单核算时间
export function setUpCloseCalculateTime(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/setUpCloseCalculateTime`,
    method: "post",
    data
  });
}
//获取未安装项目仓
export function getUninstallProjectList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/device/project/listPage`,
    method: "post",
    data
  });
}
//获取已安装项目仓
export function getInstallProjectList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/getProjectEquipListPage`,
    method: "post",
    data
  });
}
//获取项目人员
export function getProjectUserRelListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/getProjectUserRelListPage`,
    method: "post",
    data
  });
}
//添加员工
export function createProjectEmpRel(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/createProjectEmpRel`,
    method: "post",
    data
  });
}
//删除员工
export function deleteProjectEmpRel(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/deleteProjectEmpRel`,
    method: "post",
    data
  });
}
//删除片区员工
export function deleteAreaUser(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/deleteAreaUser`,
    method: "post",
    data
  });
}

//分页查询维护费用列表
export function getMaintainFeeList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/listPage`,
    method: "post",
    data
  });
}
//导出维护费用列表
export function exportMaintainFeeList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/maintainfee/export`,
    method: "post",
    data
  });
}
//批量删除费用
export function feeDelete(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/batchDelete`,
    method: "post",
    data
  });
}
//批量确认费用条目
export function confirmByTaxIds(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/confirmByTaxIds`,
    method: "post",
    data
  });
}
//编辑费用
export function updateFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/batchUpdate`,
    method: "post",
    data
  });
}
//故障原因分页查询
export function getEquiperrorreason(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/listPage`,
    method: "post",
    data
  });
}
//故障原因启用
export function enableError(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/batchEnable`,
    method: "post",
    data
  });
}
//故障原因禁用
export function disableError(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/batchDisable`,
    method: "post",
    data
  });
}
//故障原因查询
export function getReasonList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/listChildren`,
    method: "post",
    data
  });
}
//新增故障原因
export function createError(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/create`,
    method: "post",
    data
  });
}
//修改故障原因
export function updateError(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/update`,
    method: "post",
    data
  });
}
//查询安装位置
export function getEquInsPlaceList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/listPage`,
    method: "post",
    data
  });
}
//批量禁用安装位置
export function disableEquInsPlaceList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/batchDisable`,
    method: "post",
    data
  });
}
//批量启用安装位置
export function enableEquInsPlaceList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/batchEnable`,
    method: "post",
    data
  });
}

//创建安装位置信息
export function createPlace(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/create`,
    method: "post",
    data
  });
}
//编辑安装位置信息
export function updatePlace(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/update`,
    method: "post",
    data
  });
}
//查询拍照位置列表
export function getPhotoLocation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/photoLocation/listPage`,
    method: "post",
    data
  });
}
//拍照位置禁用
export function disablePhotoLocation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/photoLocation/batchDisable`,
    method: "post",
    data
  });
}
//拍照位置启用
export function enablePhotoLocation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/photoLocation/batchEnable`,
    method: "post",
    data
  });
}
//新增拍照位置
export function createPhotoLocation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/photoLocation/create`,
    method: "post",
    data
  });
}
//修改拍照位置
export function updatePhotoLocation(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/photoLocation/update`,
    method: "post",
    data
  });
}
//获取片区大区树结构
export function getListRegionalAndArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/listRegionalAndArea`,
    method: "post",
    data
  });
}
//分页查询区域地址
export function getAreaAddListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/areaAddListPage`,
    method: "post",
    data
  });
}
// 添加大区
export function createRegional(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/createRegional`,
    method: "post",
    data
  });
}
//编辑大区
export function updateRegional(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/updateRegional`,
    method: "post",
    data
  });
}
// 添加片区
export function createArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/createArea`,
    method: "post",
    data
  });
}
// 编辑片区
export function updateArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/updateArea`,
    method: "post",
    data
  });
}
// 获取区域详情
export function getAreaDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/getAreaDetail`,
    method: "post",
    data
  });
}
//添加城市/行政区域
export function createCityAdminAreas(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/createCityAdminAreas`,
    method: "post",
    data
  });
}
//区域转移
export function moveArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/moveArea`,
    method: "post",
    data
  });
}
//编辑城市/行政区域
export function updateCityAdminAreas(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/updateCityAdminAreas`,
    method: "post",
    data
  });
}
//添加街道
export function createStreet(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/createStreet`,
    method: "post",
    data
  });
}
//编辑街道
export function updateStreet(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/updateStreet`,
    method: "post",
    data
  });
}
//获取员工列表
export function userListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/userListPage`,
    method: "post",
    data
  });
}
//添加员工
export function addAreaUser(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/addAreaUser`,
    method: "post",
    data
  });
}
//区域转移
export function updateUserArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/updateUserArea`,
    method: "post",
    data
  });
}
//耗材自动上报列表查询
export function getAutoConsumeMaterialList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/auto/consumeMaterial/listPage`,
    method: "post",
    data
  });
}
//新增耗材自动上报
export function addAutoConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/auto/consumeMaterial/add`,
    method: "post",
    data
  });
}
//获取耗材详情
export function getConsumeMaterialDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/manual/consumeMaterial/get`,
    method: "post",
    data
  });
}
//编辑耗材自动上报
export function updateAutoConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/auto/consumeMaterial/update`,
    method: "post",
    data
  });
}
//耗材自动上报启用停用
export function updateStatusAutoConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/auto/consumeMaterial/updateStatus`,
    method: "post",
    data
  });
}
//上报耗材列表查询
export function getConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/manual/consumeMaterial/listPage`,
    method: "post",
    data
  });
}
//新增耗材手动上报
export function addManualConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/manual/consumeMaterial/add`,
    method: "post",
    data
  });
}
//编辑耗材手动上报
export function updateManualConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/manual/consumeMaterial/update`,
    method: "post",
    data
  });
}
//耗材手动上报启用停用
export function updateManualStatusAutoConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/manual/consumeMaterial/updateStatus`,
    method: "post",
    data
  });
}
