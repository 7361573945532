// 两种布局方案
import defaultLayout from "@/views/layout";
import { printError } from "@/utils/util";
function selectURL(node) {
  let url;
  try {
    if (node.operationList.length > 0) {
      url = node.operationList[0].route;
      if (typeof url !== "string") return "";
      // 此处做个判断，因为菜单中包含 易流云1.0和 易流云3.0 菜单
      // 跳转时做业务判断，1.0 菜单打开浏览器新标签，3.0中的target为 _blank 也打开浏览器新标签
      if (node.menuType === "e6yun3.0") {
        url =
          url.indexOf("#") > -1
            ? url.substring(url.indexOf("#") + 1)
            : `/${url}`;
      }
    }
  } catch (error) {
    printError(error);
  }
  // console.log(url, "url---");
  return url;
}

/**
 * @description: 通过 menuKey 向 allMenu 中查找 menuTree 的详细信息
 * @param {Array} menuTree
 * @param {Object} allMenu
 * @return: Array
 */

export function supplementMenuTree(menuTree, allMenu) {
  try {
    menuTree.forEach(item => {
      let node = allMenu[item.menuKey];
      item.meta = {
        menuType: node.menuType,
        title: node.title, // 菜单树名称
        url: selectURL(node), // 菜单跳转 url，这款的 url 地址需要处理一下
        icon: node.icon, // 菜单 icon 只有一级展示
        target: node.target // 是否打开新标签
      };
      if (item.children) {
        supplementMenuTree(item.children, allMenu);
      }
    });
  } catch (error) {
    printError(error);
  }
}

// function fnb(list, site = "当前位置:") {
//   return list.map(item => {
//     console.log(site, item, item.menuKey, "menuKey==");
//     // 判断有没有子节点 NO
//     if (!item.children.length) {
//       return site + item.menuKey;
//     } else {
//       console.log(site, "site==");
//       // 如果有 subs 则进行递归
//       return fnb(item.children, " > ", site + item.menuKey);
//     }
//   });
// }

/**
 * @description: 从allMenu中筛选出易流云 3.0 路由
 * @param {Object} allMenu
 * @return: Object
 */
export function filterRoutes(allMenu) {
  let defaultRoutes = []; // 默认布局路由

  try {
    Reflect.ownKeys(allMenu).forEach(menuKey => {
      let { moduleId, menuType } = allMenu[menuKey];
      if (
        menuType === "e6yun3.0" &&
        Array.isArray(allMenu[menuKey].operationList)
      ) {
        // 获取当前模块的权限列表
        let operationIds = [];
        if (Array.isArray(allMenu[menuKey].operationList)) {
          operationIds = allMenu[menuKey].operationList.map(
            item => item.operationId
          );
        }

        allMenu[menuKey].operationList.forEach(item => {
          if (item.jsPath && item.route) {
            // 组装路由结构
            let route = {
              path: item.route.trim(),
              name: item.route.trim(),
              components: item.jsPath.trim(),
              meta: {
                moduleId,
                menuType,
                operationIds,
                title: menuKey,
                modulePid: allMenu[menuKey].modulePid,
                isCache: allMenu[menuKey].isCache
              }
            };
            if (allMenu[menuKey].componentName) {
              route.meta.componentName = allMenu[menuKey].componentName.trim();
            }
            defaultRoutes.push(route);
          }
        });
      }
    });
  } catch (error) {
    printError("filterRoutes" + error.message);
  }
  return [defaultRoutes];
}

/**
 * @description: 注册路由
 * @param {Array} routes
 * @return: Array
 */
let moduleFn = path => () => import(`@/views/${path}`);

export function registeredRouter(routes) {
  routes.forEach(route => {
    route.components = {
      default: moduleFn(route.components)
    };
  });

  // let path = node.component;
  // node.component = () => import("@/views/" + path);

  return routes;
}
/**
 * @description: 生成路由
 * @param {Array} defaultRoutes // 默认布局
 * @return: Array
 */
export function generateRouter(defaultRoutes) {
  return [
    {
      path: "/",
      component: defaultLayout,
      children: registeredRouter(defaultRoutes)
    },
    {
      path: "/",
      component: defaultLayout,
      children: [
        {
          path: "403",
          name: "403",
          component: () => import("@/views/403")
        },
        {
          path: "ALL_403",
          name: "ALL_403",
          component: () => import("@/views/403ALL")
        },
        {
          path: "404",
          name: "404",
          component: () => import("@/views/404")
        }
        // {
        //   path: "test",
        //   name: "test",
        //   component: () =>
        //     import(
        //       "@/views/WOS/dataCenter/customerServiceReports/customerSatisfactionReturnVisitRateReport"
        //     )
        // }
      ]
    },
    {
      path: "*",
      name: "404",
      redirect: "/404"
    }
  ];
}
