import Cookies from "js-cookie";

export function getToken(key) {
  return Cookies.get(key);
}

export function setToken(key, value) {
  return Cookies.set(key, value);
}

export function removeToken(key) {
  return Cookies.remove(key);
}

/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

// 远程搜索滑倒底部加载（分页）
export function throttle(fn, wait = 300) {
  let previous = 0;
  return function(...args) {
    let now = +new Date();
    if (now - previous > wait) {
      previous = now;
      fn.apply(this, args);
    }
  };
}
