/*
 * @Description: 质检中心
 * @Author: ChenXueLin
 * @Date: 2021-12-25 10:45:02
 * @LastEditTime: 2022-04-08 11:50:22
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_CALLCENTER = "" // 8802
} = e3Api;
//服务单质检分页列表
export function getServiceQTList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/serviceQT/listPage`,
    method: "post",
    data
  });
}
//导出服务单质检
export function exportServiceQTList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/serviceQT/export`,
    method: "post",
    data
  });
}
//导出客户服务信息质检
export function exportInspection(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/inspection/export`,
    method: "post",
    data
  });
}
//获取服务单质检详情
export function getServiceQTDetail(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/serviceQT/detail`,
    method: "post",
    data
  });
}
//确认质检--服务单
export function confirmServiceQT(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/serviceQT/confirm`,
    method: "post",
    data
  });
}
//审核质检--服务单
export function approveServiceQT(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/serviceQT/approve`,
    method: "post",
    data
  });
}
//客户服务质检查询
export function getCustomerServiceQTList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/inspection/listPage`,
    method: "post",
    data
  });
}
//客户服务质检详情
export function getCustomerServiceQTDetail(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/inspection/detail`,
    method: "post",
    data
  });
}
//获取已经配置的配置数目
export function getConfigNumber(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/config/getNum`,
    method: "post",
    data
  });
}
//查询质检配置列表
export function getInspection(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/config/getList`,
    method: "post",
    data
  });
}
//确认质检--客户服务信息
export function confirmServiceInspection(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/inspection/update/inspection`,
    method: "post",
    data
  });
}
//确认审核--客户服务信息
export function confirmAuditInspection(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/inspection/update/check`,
    method: "post",
    data
  });
}
//添加同级节点
export function createConfig(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/config/create`,
    method: "post",
    data
  });
}
//编辑节点
export function updateConfig(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/config/update`,
    method: "post",
    data
  });
}
//删除同级节点
export function deleteConfig(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/config/delete`,
    method: "post",
    data
  });
}
