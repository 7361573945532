/*
 * @Description: 页面基础混合
 * @Author: sunxiaodong
 * @Date: 2020-08-05 17:06:06
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-09-01 14:11:47
 */
import setting from "../setting";
import tableTitle from "@/components/tableTitle";
import { get } from "@/utils/util";
import { mapGetters, mapActions } from "vuex";
import { shortcuts } from "@/utils/util";
const { loadingBackground } = setting;
import { printError, isObject } from "@/utils/util";
import { getCorpMsg } from "@/api";
export default {
  data() {
    return {
      checkedValue: "",
      loading: false, // 页面级 loading
      loadingBackground, // 页面级 loading 颜色
      timePickerResetList: [], // 重置是需要重置的时间控件列表 item: timePicker refname
      tableScrollTop: 0,
      refTable: "tableList",
      selColumns: [],
      // 客户下拉列表
      corpListLoaing: false, // 客户下拉列表loading
      corpList: [] // 客户下拉列表枚举
    };
  },
  components: {
    tableTitle
  },
  computed: {
    ...mapGetters(["cachedViews", "visitedViews", "operationIds", "allMenu"])
  },
  mounted() {
    // 放在eventloop后面
    setTimeout(() => {
      this.isInitComponennts = true; // 更改初始化标记
    }, 0);
  },
  methods: {
    //处理表头标题过长，鼠标移上去显示全名
    renderHeader(h, data) {
      return h("span", [
        h(
          "el-tooltip",
          {
            attrs: {
              class: "item",
              effect: "dark",
              content: data.column.label,
              placement: "top"
            }
          },
          [h("span", data.column.label)]
        )
      ]);
    },
    ...mapActions("tagsView", ["delVisitedView", "delCachedView"]),
    /*
     ** 路由跳转方法，在路由跳转之前做判断，如果是打开新页面，params.refresh = false,
     ** 如果是打开已有页面 params.refresh = true
     ** !!注意!! 路由的跳转一定要用 name 跳转，不要使用 path
     */
    routerPush(data) {
      // 由于 点击浏览器回退按钮params是一个参数都没有的，所以params一个参数都没有时不更新 vuex 和localStore 的路由
      // 同时系统内跳转需要保证至少有一个参数传参跳转 以保证路由参数正常更新或删除
      if (isObject(data) && isObject(data.params)) {
        data.params.refresh = data.params.refresh || false;
        this.$router.push(data);
      }
    },
    /**
     * @desc 获取带param参数的route,页面直接刷新时无param参数 需要去localStorege拿
     * @return {route}
     */
    getHasParamsRoute() {
      let params = this.$route.params;
      if (Object.keys(params).length) {
        return this.$route;
      }
      return this.getLocalStorageRoute() || this.$route;
    },
    /**
     * @desc 获取LocalStorage当前页面的路由
     * @return {route | undefined}
     */
    getLocalStorageRoute() {
      let visitedViews = window.localStorage.getItem("visitedViews");
      try {
        visitedViews = JSON.parse(visitedViews);
        return visitedViews.find(item => {
          return item.name === this.$route.name;
        });
      } catch (error) {
        console.log(error);
        return;
      }
    },
    /*
     ** 处理后台返回的数据，默认 code=1 时，根据路径获取 object 中的属性值
     */
    getFreezeResponse(
      object,
      { path = "result", defaultVal = [], freeze = true, code = ["OK"] } = {}
    ) {
      if (code.includes(object.code)) {
        return get(object, path, defaultVal, freeze);
      } else {
        return defaultVal;
      }
    },
    /*
     ** 删除 tag, 返回值是个 promise ;
     */
    closeTag(view) {
      return Promise.all([this.delVisitedView(view), this.delCachedView(view)]);
    },

    /**
     * @desc 判断按钮权限
     * 如果 menuKey 不存在，检查当前模块的按钮权限，否则检查该 menuKey 模块按钮权限
     * @param { Number } id 操作Id
     * @param { String } [menuKey = ""] 指定模块的menuKey
     * @return
     */
    showOperateBtn(id, menuKey = "") {
      if (menuKey) {
        // 获取权限列表
        const { [menuKey]: { operationList = [] } = {} } = this.allMenu;
        // 从权限列表中遍历出操作列表
        const operationIds = operationList.map(item => item.operationId);
        // 判断是否有权限
        return operationIds.includes(id);
      } else {
        return this.operationIds.includes(id);
      }
    },
    //权限模块的按钮控制
    showActiveBtn(menuKey, activeName) {
      // 获取权限列表
      const { [menuKey]: { operationList = [] } = {} } = this.allMenu;
      // 从权限列表中遍历出操作列表
      const operationIds = operationList.map(item => item.operationName);
      // 判断是否有权限
      return operationIds.includes(activeName);
    },
    /**
     * @desc 过滤表格操作按钮权限
     * @param { Object[] } operateList 操作列表
     * @param { Number } operateList[].id 操作权限Id
     * @param { String } operateList[].label 操作名称
     * @return { Array<Object> } 根据权限过滤后的操作列表
     */
    filterOperateBtn(operateList) {
      operateList = operateList || [];
      return operateList.filter(item => {
        return this.operationIds.some(id => item.id === id);
      });
    },

    // 每页条数改变
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
      this.queryList();
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      this.queryList();
      this.scrollTop = 0;
    },
    // 排序改变
    sortChange(val) {
      if (!this.searchForm) {
        return;
      }
      this.searchForm.sortDir = val.order;
      this.searchForm.sortIndex = this.searchForm.sortDir ? val.prop : "";
      this.handleSearch();
    },
    //重置
    handleReset() {
      this.$refs.searchForm.resetFields();
      this.timePickerResetList.map(item => {
        if (this.$refs[item]) this.$refs[item].reset();
      });
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //勾选多选框
    //多选数据
    handleSelectionChange(columns) {
      this.selColumns = columns;
    },
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryList() {
      if (!this.queryListAPI) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.queryListAPI(this.searchForm);
        this.tableData =
          this.formatList && this.formatList !== undefined
            ? this.formatList(res.data.array)
            : this.getFreezeResponse(res, {
                path: "data.array"
              });

        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 重新布局element表格
     *
     */
    elementTableDoLayout() {
      this.$nextTick(() => {
        if (this.$refs[this.refTable]) this.$refs[this.refTable].doLayout();
      });
    },
    /*
     ** 缓存table页面的回显逻辑 需要在对应页面配置 tableScrollDOM
     ** 1.回到原第几分页 (keepAlive自带不需处理)
     ** 2.刷新页面(重新执行 queryList)
     ** 3.回到原滚动位置(使用监听原生滚动事件记录滚动高度，回来然再控制回滚)
     */
    // 设置监听的滚动tableDOM 注意需要设置对应 el-table 的ref
    setTableScrollDOM(refKey = "tableList") {
      if (!this.$refs[refKey]) {
        return;
      }
      this.tableScrollDOM = this.$refs[refKey].bodyWrapper;
      if (!this.tableScrollDOM.onscroll) {
        this.tableScrollDOM.onscroll = ev => {
          this.tableScrollTop = ev.target.scrollTop;
        };
      }
    },
    // table页恢复原来的滚动位置 刷新
    async setScrollRecord() {
      if (!this.tableScrollDOM) {
        return;
      }
      let needQueryList = this.$route.params.refresh;
      if (needQueryList) {
        this.handleReset();
        this.searchForm.pageIndex = 1;
        this.tableScrollTop = 0;
        await this.queryList();
      }
      setTimeout(() => {
        this.tableScrollDOM.scrollTop = this.tableScrollTop || 0;
      }, 0);
    },
    // 编辑页的 刷新函数 写在activated生命周期里
    handleRefreshUpdatePage() {
      if (!this.isInitComponennts) {
        this.isInitComponennts = true;
        return;
      }
      let refresh = this.$route.params.refresh;
      if (!refresh) return;
      if (this.initForm) this.initForm();
    },
    //获取下拉框选中的name
    filterSelect(data, prop, val) {
      //data：遍历的数组,prop数组对象的key,val数组对象的value
      let obj = {};
      data.forEach(item => {
        if (item[prop] == val) {
          obj = item;
        }
      });
      return obj;
    },
    //加载客户名称
    async loadCorpData(val) {
      try {
        this.corpListLoaing = true;
        let res = await getCorpMsg({
          corpId: "",
          pageNum: 1,
          pageSize: 200,
          isManageEmp: false,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpListLoaing = false;
      }
    },
    //全部员下拉框处理逻辑
    handleLoadEmployee: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val);
      }
    }, 300),
    // 时间范围搜索框，开始时间禁止大于结束时间
    pickerOptions(targetPath) {
      let startDisabledDate = time => {
        let target = _.get(this, targetPath, []);
        let endTime = target[1];
        let timeStamp = moment(time).valueOf();
        return endTime ? timeStamp > endTime : false;
      };
      let endDisabledDate = time => {
        let target = _.get(this, targetPath, []);
        let startTime = target[0];
        let timeStamp = moment(time).valueOf();
        return startTime ? timeStamp < startTime : false;
      };
      return {
        shortcuts: shortcuts({ d: [0, -1, -7, -10, -30] }),
        startDisabledDate,
        endDisabledDate
      };
    }
  }
};
