/*
 * @Description: SIM卡管理
 * @Author: ChenXueLin
 * @Date: 2021-11-03 18:11:11
 * @LastEditTime: 2021-12-25 17:23:21
 * @LastEditors: LiangYiNing
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_STE = "" // 8802
} = e3Api;

// 获取sim卡列表
export function getSimList(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/list`,
    method: "post",
    data
  });
}
// sim卡导出
export function simExport(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/export`,
    method: "post",
    data
  });
}
// 激活/开通/报停
export function updateSim(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/status/update`,
    method: "post",
    data
  });
}
// 通过simNo开通/报停
export function batchUpdateSimBySimNo(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/status/batchUpdate`,
    method: "post",
    data
  });
}
// 通过simNo修改卡信息
export function updateSimBySimNo(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/update`,
    method: "post",
    data
  });
}

// 批量修改Sim卡信息
export function batchupdateSim(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/batchupdate`,
    method: "post",
    data
  });
}
// SIM卡详情
export function simDetail(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/info`,
    method: "post",
    data
  });
}
// 获取需要修改的卡信息
export function getSimNeedUpdateData(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/update/info`,
    method: "post",
    data
  });
}
// sim卡编辑备注
export function updateRemark(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/remark/update`,
    method: "post",
    data
  });
}

/*******卡套餐*************/

//查询卡套餐列表
export function templateList(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/list`,
    method: "post",
    data
  });
}
//获取卡套餐枚举
export function getPackageEnum(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/getPackage`,
    method: "post",
    data
  });
}
//获取设备三级分类枚举
export function getEquipThirdEnum(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/getEquipThird`,
    method: "post",
    data
  });
}
//删除卡套餐
export function deleteTemplate(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/delete`,
    method: "post",
    data
  });
}

//启用卡套餐
export function enableTemplate(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/enable`,
    method: "post",
    data
  });
}
//禁用卡套餐
export function disenableTemplate(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/disenable`,
    method: "post",
    data
  });
}
//新增卡套餐
export function createTemplate(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/create`,
    method: "post",
    data
  });
}
//编辑卡套餐
export function updateTemplate(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simPackage/update`,
    method: "post",
    data
  });
}

/*******卡属性*************/
//查询卡属性列表
export function simPropList(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/list`,
    method: "post",
    data
  });
}
//获取卡属性枚举
export function getPropEnum(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/getProp`,
    method: "post",
    data
  });
}
//删除卡属性
export function deleteSimProp(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/delete`,
    method: "post",
    data
  });
}

//启用卡属性
export function enableSimProp(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/enable`,
    method: "post",
    data
  });
}
//禁用卡属性
export function disenableSimProp(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/disenable`,
    method: "post",
    data
  });
}
//新增卡属性
export function createSimProp(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/create`,
    method: "post",
    data
  });
}
//编辑卡属性
export function updateSimProp(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simProp/update`,
    method: "post",
    data
  });
}
/*******卡类型*************/
//查询卡类型列表
export function simTypeList(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/list`,
    method: "post",
    data
  });
}
//获取卡属性枚举
export function getTypeEnum(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/getType`,
    method: "post",
    data
  });
}
//删除卡属性
export function deleteSimTypep(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/delete`,
    method: "post",
    data
  });
}

//启用卡类型
export function enableSimTypep(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/enable`,
    method: "post",
    data
  });
}
//禁用卡类型
export function disenableSimTypep(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/disenable`,
    method: "post",
    data
  });
}
//新增卡类型
export function createSimType(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/create`,
    method: "post",
    data
  });
}
//编辑卡类型
export function updateSimType(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/simType/update`,
    method: "post",
    data
  });
}

//卡配置数量
export function simConfigCount(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/count`,
    method: "post",
    data
  });
}
