/*
 * @Description: axios 配置文件
 * @Author: sunxiaodong
 * @Date: 2020-02-05 16:17:19
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-07-13 11:44:33
 */

/* 默认示例 */

import axios from "axios";
import { printError } from "@/utils/util";
import setting from "@/setting";
import { Message } from "element-ui";
import { MessageBox } from "element-ui";
import router from "e6-base-core/router";
const service = axios.create({
  baseURL: "/", // api 的 base_url
  timeout: 1000 * 120, // 超时时间-120秒
  headers: {
    token: Cookies.get("E3RequestToken")
  }
});

/**
 * @description: 全局处理后台 code 为 105
 * @param { Object }  // failure: 失败，refuse: 不允许，success: 成功
 * @return { * }
 */
function dispose105({ failure = 0, refuse = 0, success = 0 } = {}) {
  let total = failure + refuse + success;
  let str = "";

  if (total !== 0) {
    str = `<div>共${total}条数据</div>`;
    if (success !== 0) {
      str += `<div class="status success"><i class="e6-icon-check_fill"></i>${success}条删除成功</div>`;
    }
    if (failure !== 0) {
      str += `<div class="status failure"><i class="e6-icon-delete_fill"></i>${failure}条删除失败</div>`;
    }
    if (refuse !== 0) {
      str += `<div class="status refuse"><i class="e6-icon-abnormal_fill"></i>${refuse}条无法删除</div>`;
    }
  }

  MessageBox.alert(str, "提示", {
    customClass: "el-message-global",
    dangerouslyUseHTMLString: true,
    confirmButtonText: "知道了"
  });
}
service.interceptors.request.use(
  config => {
    let token = Cookies.get("E3RequestToken");
    token && (config.headers.token = token);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// axios 请求拦截器
/* service.interceptors.request.use(config => {
  return config;
}); */
service.interceptors.response.use(
  response => {
    const {
      headers = {},
      data: { code, msg, result }
    } = response;
    switch (code) {
      case 0: // 系统错误
      case 100: // 后台参数校验失败
      case "InvalidParameter": //
        Message.error(msg);
        break;
      case "InternalServerError":
        Message.error(msg);
        break;
      case "existWorkingItem": //
        Message.error(msg);
        break;
      case "VersionError":
        Message.error(msg);
        break;
      case "AlreadyExisted":
        Message.error(msg);
        break;
      case "INSUFFICIENT_INVENTORY":
        Message.error(msg);
        break;
      case "MissingParameter":
        Message.error(msg);
        break;
      case "OperationDenied":
        Message.error(msg);
        break;
      case "NotFound":
        Message.error(msg);
        break;
      case "":
        Message.error(msg);
        break;
      case 401: // 未登录
        router.replace("/login");
        // Message.error(msg);
        break;
      case 105: // 批量操作，部分成功，部分失败
        dispose105(result);
        break;
    }
    const ctd = "content-disposition";
    const ctdUpper = "Content-Disposition";

    if (code == 401) {
      Cookies.remove("needEditPwd");
      router.replace("/login");
      return Promise.reject("登录过期");
    }

    // 判断该接口是普通接口，还是数据导出接口
    if (
      (headers[ctdUpper] && headers[ctdUpper].indexOf("attachment") > -1) ||
      (headers[ctd] && headers[ctd].indexOf("attachment") > -1)
    ) {
      return _.cloneDeep(response);
    } else {
      return _.cloneDeep(response.data);
    }
  },
  error => {
    const { status } = error.response;
    switch (status) {
      case 401:
        window.location.href = setting.loginURL;
        break;
      default:
        printError(error);
        Message.error("网络请求异常！");
    }

    return error;
  }
);
export default service;
