/*
 * @Description: 系统相关数据
 * @Author: sunxiaodong
 * @Date: 2020-04-09 17:30:32
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-07-27 10:28:43
 */
const state = {
  sidebar: {
    opened: true
  },
  isScreenfull: false,
  volumeUnit: "", // 体积单位
  weightUnit: "", // 重量单位
  // 取消请求token数组
  cancelTokenArr: []
};

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
  },
  TOGGLE_SCREEN: (state, payload) => {
    console.log(payload, "payload24---");
    state.isScreenfull = payload;
  },
  SET_TOKEN(state, payload) {
    state.cancelTokenArr.push(payload.cancelToken);
  },
  CLEAR_TOKEN({ cancelTokenArr }) {
    cancelTokenArr.forEach(item => {
      item.cancel(`路由跳转,取消url为：${item.url}的请求`);
    });
    cancelTokenArr = [];
  },

  SET_UNIT(state, payload) {
    let { volumeUnitStr = "", weightUnitStr = "" } = payload;
    state.volumeUnit = volumeUnitStr;
    state.weightUnit = weightUnitStr;
  },
  RESET_ALL_STATE: state => {
    state.sidebar = {
      opened: true
    };
    state.volumeUnit = "";
    state.weightUnit = "";
  }
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  toggleScreen({ commit }, data) {
    commit("TOGGLE_SCREEN", data);
  },
  setUnit({ commit }, unit) {
    commit("SET_UNIT", unit);
  }
};

export const namespace = "app";

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
