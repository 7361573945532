/** * 查询表单项: 搜索下拉 */
<template>
  <el-select
    v-model="currentVal"
    filterable
    remote
    clearable
    :loading="loading"
    placeholder="请输入关键词"
    :remote-method="keywords => remoteMethod(keywords)"
    @change="change"
    :disabled="disabled"
    :title="filterTitle(currentVal)"
    :style="{ width: width + 'px' }"
    class="remoteSelectItem"
    ref="remoteSelect"
    @clear="handleClear"
  >
    <el-option
      v-for="item in options"
      :key="item[itemOptions.valueKey]"
      :label="item[itemOptions.labelKey] || item.label"
      :value="item[itemOptions.valueKey] || item.value"
      :title="item[itemOptions.labelKey] || item.label"
    >
      <span v-if="item.label">{{ item.label }}</span>
      <span v-if="item.longLabel">({{ item.longLabel }})</span>
    </el-option>
  </el-select>
</template>

<script>
import { queryMaterialList } from "@/api";
import { printError } from "@/utils/util";
export default {
  props: {
    value: [String, Number, Boolean, Date, Object, Array],
    defaultValue: {
      type: [String, Number, Boolean, Date, Object, Array],
      default: ""
    },
    width: {
      type: Number,
      default: 200
    },
    itemOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      remoteParams: {
        pageSize: 200,
        pageIndex: 1
      },
      queryApi: queryMaterialList, // 搜索下拉的接口
      loading: false,
      options: [],
      currentNode: {}
    };
  },
  mounted() {
    if (JSON.stringify(_.cloneDeep(this.defaultValue)) != "{}") {
      this.currentNode = _.cloneDeep(this.defaultValue);
      if (
        !this.options.some(
          item => item.materialNo === this.defaultValue.materialNo
        )
      ) {
        this.options.unshift(_.cloneDeep(this.defaultValue));
      }
    }
    this.remoteMethod();
  },
  computed: {
    // 双向绑定数据值
    currentVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      }
    }
  },

  methods: {
    change(val) {
      if (!val) {
        return;
      }
      let node = this.options.find(item => {
        return item[this.itemOptions.valueKey] == val;
      });
      this.currentNode = { ...node };
      this.$emit("change", val, node);
    },
    filterTitle(val) {
      //过滤title
      if (val !== "") {
        let result = this.options.filter(item => item.materialNo === val);
        if (result.length > 0) {
          return result[0].materialName;
        }
      }
    },
    remoteMethod(keywords) {
      let remoteParams = {
        ...this.remoteParams,
        materialName: keywords,
        pageIndex: 1
      };
      this.remoteParams = remoteParams;
      this.$tools.throttle(() => {
        this.getSelectOptions(false);
      }, 500)();
    },
    async getSelectOptions(isConcat) {
      try {
        this.loading = true;
        let queryApi = this.itemOptions.queryApi || this.queryApi;
        let res = await queryApi(this.remoteParams);
        let list = res.data.array;
        let totalCount = res.data.totalRecords;
        if (isConcat) {
          this.options = this.options.concat(list);
        } else {
          this.options = list;
        }
        this.remoteParams = {
          ...this.remoteParams,
          totalCount
        };
        if (
          JSON.stringify(_.cloneDeep(this.currentNode)) != "{}" &&
          !this.options.some(
            item => item.materialNo === this.currentNode.materialNo
          )
        ) {
          this.options.unshift(this.currentNode);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClear() {
      this.options = [];
      if (
        JSON.stringify(_.cloneDeep(this.currentNode)) != "{}" &&
        !this.options.some(
          item => item.materialNo === this.currentNode.materialNo
        )
      ) {
        this.options.unshift(this.currentNode);
      }
    }
  }
};
</script>

<style lang="scss">
.remoteSelectItem {
  .el-input {
    width: 100% !important;
  }
}

.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        font-size: 12px;
        padding-left: 20px;
        padding-right: 10px;
        height: 22px;
        line-height: 22px;
        // width: 100%;
        // display: inline-block;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }
}
</style>
