/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-07-27 10:30:03
 * @LastEditors: ChenXueLin
 */

import versionTood from "@/libs/versionUpdate";
import Cookies from "js-cookie";
import router from "e6-base-core/router";
import store from "e6-base-core/store";
import { get } from "@/utils/util";
import { supplementMenuTree, filterRoutes, generateRouter } from "@/utils/menu";
import { fetchMenuTree, fetchAllMenu } from "@/api";
import { Message } from "element-ui";
import { Loading } from "element-ui";
// import setting from "@/setting";

import { handleRoute } from "@/config/route";

const whiteList = ["/login"]; // 白名单界面

let isFetchMenu = false;

router.beforeEach(async (to, from, next) => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  // 白名单直接放行
  if (whiteList.includes(to.path)) {
    next();
  } else {
    if (Cookies.get("E3RequestToken")) {
      let operationIds =
        to.meta && Array.isArray(to.meta.operationIds)
          ? to.meta.operationIds
          : [];

      store.commit("permission/SET_OPERATION_IDS", operationIds);
      // 如果请求过菜单直接放行
      if (isFetchMenu) {
        if (to.matched.length) {
          next();
        } else {
          next({ name: "404" });
        }
      } else {
        // 说明已经发送过请求了
        isFetchMenu = true;
        const initLoading = Loading.service({ fullscreen: true });
        try {
          let menuTree = []; // 菜单结构
          let allMenu = {}; // 路由结合
          let routes = []; // 易流云 3.0 动态路由
          // 请求数据
          const [menuTreeRes, allMenuRes] = await Promise.all([
            fetchMenuTree(),
            fetchAllMenu()
          ]);
          menuTree = get(menuTreeRes, "data.main", []);
          allMenu = get(allMenuRes, "data", {});
          if (menuTree.length && Reflect.ownKeys(allMenu).length > 0) {
            // 完善 menuTree 信息
            // debugger
            supplementMenuTree(menuTree, allMenu);
          }
          // 过滤出易流云 3.0 路由
          // 因为 allMenu 是包含 .net、易流云1.0、易流云3.0所有界面
          // 因此筛选出 易流云3.0 路由，通过 allMenu 中的 menuType 为 e6yun3.0 来缺点易流云3.0路由
          let [defaultRoutes] = filterRoutes(allMenu);
          // 生成路由
          routes = generateRouter(defaultRoutes);
          // for (let key in allMenu) {
          //   if (key == "来去电工作台") {
          //     routes.push({
          //       path: "/playScreen",
          //       name: "playScreen",
          //       component: workbench
          //     });
          //   }
          // }
          routes = Object.freeze(routes); // 冻结数据
          // 注册路由
          let dynamicalRoutes = handleRoute(menuTree, allMenu);
          router.addRoutes([...dynamicalRoutes, ...routes]);
          // router.addRoutes(routes);
          await store.dispatch("permission/setMenu", Object.freeze(menuTree));
          await store.dispatch("permission/setAllMenu", Object.freeze(allMenu));
          await store.dispatch("permission/setRoutes", Object.freeze(routes));
          // await store.dispatch("app/setUserInfo", Object.freeze(userInfo));
          // await store.dispatch("app/setUnit", Object.freeze(unit));
          if (!menuTree.length) {
            next({ name: "ALL_403" });
          } else {
            next({ ...to });
          }
        } catch (error) {
          // remove token and go to login page to re-login
          Message.error({
            message: error || "获取菜单失败",
            duration: 1000,
            onClose() {
              // window.location.href = setting.loginURL;
              // router.replace("/login");
            }
          });
        } finally {
          initLoading.close();
        }
      }
    } else {
      router.replace("/login");
    }
  }
});

/* 重置 是否请求过菜单接口 */
export function resetFetchMenu() {
  this.isFetchMenu = false;
}
