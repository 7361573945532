<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-07-27 11:54:00
 * @LastEditors: ChenXueLin
-->
<template>
  <div id="app">
    <router-view />
    <change-password
      ref="changePasswordRef"
      :showClose="false"
      needPasswordSimpleTips
    ></change-password>
  </div>
  <!--  @updatePwd="updatePwd" -->
</template>

<script>
import changePassword from "./views/layout/components/Navbar/changePassword.vue";
import { mapActions } from "vuex";
import websocketMinxins from "@/mixins/websocketMinxins";
import eventBus from "@/bus";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      addContactVisible: true,
      needEditPwd: false,
      beforeunloadTime: 0,
      unloadTime: 0
    };
  },
  mixins: [websocketMinxins],
  watch: {
    needEditPwd: {
      immediate: true,
      handler(val) {
        if (
          val &&
          Cookies.get("E3RequestToken") &&
          Cookies.get("E3RequestToken") != "null"
        ) {
          this.$refs.changePasswordRef.show();
        }
      }
    }
  },
  computed: {
    ...mapGetters(["isScreenfull"])
  },
  components: {
    changePassword
  },
  created() {
    if (
      Cookies.get("E3RequestToken") &&
      Cookies.get("E3RequestToken") != "null"
    ) {
      this.setUserInfo();
    }
  },
  mounted() {
    //添加监听事件
    window.addEventListener("beforeunload", e => this.beforeunloadHandler(e));
    window.addEventListener("unload", e => this.unload(e));
    // 修复 IE 11 下路由不跳转
    function checkIE() {
      return (
        "-ms-scroll-limit" in document.documentElement.style &&
        "-ms-ime-align" in document.documentElement.style
      );
    }
    if (checkIE()) {
      window.addEventListener(
        "hashchange",
        () => {
          let currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
    this.needEditPwd = Cookies.get("needEditPwd");
    this.$router.onReady(() => {
      if (!this.$route.query.needClose) {
        this.handleWebsocket();
      }
    });
  },
  methods: {
    ...mapActions("userInfo", ["setUserInfo"]),
    //处理websocket逻辑
    handleWebsocket() {
      if (Cookies.get("PhoneExt") && Cookies.get("PhoneExt") != "null") {
        this.initWebSocket();
        eventBus.$on("closeWebsocket", () => {
          this.close();
        });
      }
    },
    beforeunloadHandler() {
      this.beforeunloadTime = new Date().getTime();
    },
    unload() {
      this.unloadTime = new Date().getTime();
      // window.localStorage.setItem('timer', String(this.unloadTime - this.beforeunloadTime))
      // 本地通过localStorage中的数据看出,关闭事件间隔小于1,刷新则大于8
      if (this.unloadTime - this.beforeunloadTime <= 5 && this.isScreenfull) {
        let mainUniqueIdList = window.localStorage.getItem("mainUniqueIdList")
          ? JSON.parse(window.localStorage.getItem("mainUniqueIdList"))
          : [];
        let updateIdList = mainUniqueIdList.filter(
          item => item != this.$route.query.mainUniqueId
        );
        window.localStorage.setItem(
          "mainUniqueIdList",
          JSON.stringify(updateIdList)
        );
      }
    }
  },
  destroyed() {
    // 销毁监听
    // this.websocketclose();
    window.removeEventListener("beforeunload", e =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", e => this.unload(e));
  }
};
</script>

<style>
div:focus {
  outline: none;
}
body .el-table th.gutter {
  display: table-cell !important;
}
</style>
