const state = {
  //当前菜单
  currentMenu: null,
  visitedViews: [],
  cachedViews: []
};

const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return;
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || "no-name"
      })
    );
  },

  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
  },

  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.name === view.name;
    });
  },

  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  },

  ADD_CACHED_VIEW: (state, view) => {
    if (!view.meta.isCache || !view.meta.componentName) return;
    if (state.cachedViews.includes(view.meta.componentName)) return;
    state.cachedViews.push(view.meta.componentName);
    // if (view.meta.cache === true) {
    //   state.cachedViews.push(view.name);
    // }
  },

  DEL_CACHED_VIEW: (state, view) => {
    if (!view.meta.isCache || !view.meta.componentName) return;
    const index = state.cachedViews.indexOf(view.meta.componentName);
    index > -1 && state.cachedViews.splice(index, 1);
  },

  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    if (!view.meta.isCache || !view.meta.componentName) return;
    const index = state.cachedViews.indexOf(view.meta.componentName);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },

  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = [];
  },

  RESET_ALL_STATE: state => {
    state.visitedViews = [];
    state.cachedViews = [];
  },

  SELECT_MENU: state => {
    // view
    // console.log(state, view, "view===");
    state.currentMenu = null;
  }
};

const actions = {
  addVisitedView({ commit }, view) {
    commit("ADD_VISITED_VIEW", view);
  },

  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit("DEL_VISITED_VIEW", view);
      resolve([...state.visitedViews]);
    });
  },

  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit("DEL_OTHERS_VISITED_VIEWS", view);
      resolve([...state.visitedViews]);
    });
  },

  updateVisitedView({ commit }, view) {
    commit("UPDATE_VISITED_VIEW", view);
  },

  addCachedView({ commit }, view) {
    commit("ADD_CACHED_VIEW", view);
  },

  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit("DEL_CACHED_VIEW", view);
      resolve([...state.cachedViews]);
    });
  },

  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit("DEL_OTHERS_CACHED_VIEWS", view);
      resolve([...state.cachedViews]);
    });
  },

  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit("DEL_ALL_CACHED_VIEWS");
      resolve([...state.cachedViews]);
    });
  },
  selectMenu({ commit }, view) {
    // console.log(view, "134===");
    commit("SELECT_MENU", view);
  }
};

export const namespace = "tagsView";

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
