/*
 * @Description:协同单管理
 * @Author: ChenXueLin
 * @Date: 2021-10-09 15:19:03
 * @LastEditTime: 2021-11-30 11:46:44
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_EWOS = "" //
} = e3Api;

// 查询协同单列表
export function teamTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTask/coordinate/listPage`,
    method: "post",
    data
  });
}
// 协同单基本信息详情
export function teamTaskDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTask/detail`,
    method: "post",
    data
  });
}
//获取协同单流转信息
export function getTeamProcess(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTaskOperation/coordinateCirculation/info`,
    method: "post",
    data
  });
}
//协同单关联单据
export function bindBillsList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/xietongDetail/relation`,
    method: "post",
    data
  });
}
//创建协同单
export function createTeamTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTask/create`,
    method: "post",
    data
  });
}
//催单
export function reminders(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTaskOperation/reminders`,
    method: "post",
    data
  });
}
//指派工程师
export function assignTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTaskOperation/coordinationAssign`,
    method: "post",
    data
  });
}
//指派工程师
export function handleTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTaskOperation/handleCoordinateTask`,
    method: "post",
    data
  });
}
//完结工单
export function finishTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/synergyTask/coordinationFinish`,
    method: "post",
    data
  });
}
