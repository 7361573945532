<template>
  <div id="tags-view-container" class="tags-view-container">
    <scroll-pane ref="scrollPane">
      <router-link
        v-for="(tag, index) in visitedViews"
        ref="tag"
        :key="index"
        :to="
          Object.assign({}, tag, {
            params: { ...tag.params, refresh: false, isTab: true }
          })
        "
        tag="span"
        class="tags-view-item"
        exact
        @contextmenu.prevent.native="openMenu(tag, $event)"
        >{{ tag.title }}
        <span
          class="el-icon-close"
          v-if="visitedViews.length > 1"
          @click.prevent.stop="closeSelectedTag(tag)"
        />
      </router-link>
    </scroll-pane>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click="refreshSelectedTag(selectedTag)">刷新</li>
      <template v-if="visitedViews.length > 1">
        <li @click="closeSelectedTag(selectedTag)">关闭</li>
        <li @click="closeOthersTags">关闭其他</li>
      </template>
    </ul>
  </div>
</template>

<script>
import ScrollPane from "./ScrollPane";
import { mapGetters, mapActions } from "vuex";
// import path from "path";
import eventBus from "@/bus";

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 26,
      left: 0,
      selectedTag: {},
      affixTags: []
    };
  },

  computed: {
    ...mapGetters(["visitedViews"]),

    scrollWrapper() {
      return this.$refs.scrollPane.$refs.scrollContainer.$refs.wrap;
    }
  },

  watch: {
    $route() {
      this.addTags();
      this.updateTags();
      this.moveToCurrentTag();
    },

    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    }
  },

  mounted() {
    this.scrollWrapper.addEventListener("scroll", this.closeMenu, true);
    this.initTags();
  },

  methods: {
    ...mapActions("tagsView", [
      "addVisitedView",
      "updateVisitedView",
      "delVisitedView",
      "delOthersVisitedViews",
      "addCachedView",
      "delCachedView",
      "delOthersCachedViews"
    ]),

    isActive(route) {
      return route.path === this.$route.path;
    },

    initTags() {
      let visitedViews = window.localStorage.getItem("visitedViews");
      try {
        visitedViews = JSON.parse(visitedViews);
        visitedViews.map(item => {
          this.addVisitedView(item);
          this.addCachedView(item);
          this.saveVisitedViews();
        });
      } catch (error) {
        console.log(error);
      }
      this.addTags();
    },

    addTags() {
      const { name } = this.$route;
      if (name) {
        this.addVisitedView(this.$route);
        this.addCachedView(this.$route);
      }
      return false;
    },

    updateTags() {
      const { name } = this.$route;
      // 由于 点击浏览器回退按钮params是一个参数都没有的，所以params一个参数都没有时不更新 vuex 和localStore 的路由
      // 同时系统内跳转需要保证至少有一个参数传参跳转 以保证路由参数正常更新或删除
      if (name && Object.keys(this.$route.params).length) {
        this.updateVisitedView(this.$route);
        this.saveVisitedViews();
      }
      return false;
    },

    closeSelectedTag(view) {
      if (this.keepOneTag()) {
        Promise.all([this.delVisitedView(view), this.delCachedView(view)]).then(
          ([visitedViews]) => {
            if (this.isActive(view)) {
              this.toLastView(visitedViews, view);
            }
            this.saveVisitedViews();
          }
        );
      }
    },

    closeOthersTags() {
      if (this.keepOneTag()) {
        let selectedTag = this.selectedTag;
        let currentRoute = this.$route;

        // 如果鼠标选中路由的 name 和当前页面路由的 name 相同，则路由不跳转
        if (selectedTag.name !== currentRoute.name) {
          this.$router.push(selectedTag);
        }

        Promise.all([
          this.delOthersVisitedViews(selectedTag),
          this.delOthersCachedViews(selectedTag)
        ]).then(() => {
          this.moveToCurrentTag();
          this.saveVisitedViews();
        });
      }
    },

    refreshSelectedTag(view) {
      if (this.$route.name !== view.name) {
        this.$router.push(view);
      }
      eventBus.$emit("refreshTab", view);

      // this.$store.dispatch("tagsView/delCachedView", view).then(() => {
      //   const { fullPath } = view;
      //   this.$router.replace({
      //     path: "/redirect" + fullPath,
      //     params: view.params
      //   });
      // });
    },

    moveToCurrentTag() {
      const tags = this.$refs.tag;
      if (Array.isArray(tags)) {
        this.$nextTick(() => {
          for (const tag of tags) {
            if (tag.to.path === this.$route.path) {
              this.$refs.scrollPane.moveToTarget(tag);
              break;
            }
          }
        });
      }
    },

    openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }

      // this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },

    closeMenu() {
      this.visible = false;
    },

    toLastView(visitedViews) {
      const latestView = visitedViews.slice(-1)[0];
      this.$router.push(latestView);
    },

    keepOneTag() {
      if (this.visitedViews.length < 2) {
        this.$message.warning("至少保留一个标签页!");
        return false;
      } else {
        return true;
      }
    },
    // 保存visitedViews到localStorege
    saveVisitedViews() {
      let visitedViews = this.visitedViews.map(item => {
        return {
          fullPath: item.fullPath,
          hash: item.hash,
          meta: item.meta,
          name: item.name,
          params: item.params,
          path: item.path,
          query: item.query,
          title: item.title
        };
      });
      window.localStorage.setItem("visitedViews", JSON.stringify(visitedViews));
    }
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.closeMenu);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.tags-view-container {
  height: 100%;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-bottom-width: 0;
    color: #79889a;
    background: #fff;
    padding: 0 12px;
    font-size: 12px;
    box-sizing: border-box;
    &.router-link-active,
    &.router-link-exact-active {
      color: $--color-primary;
      background-color: #ecf8fd;
    }
    .el-icon-close {
      margin-left: 10px;
      &:hover {
        color: #46bfea;
      }
    }
  }
  /deep/ {
    .el-scrollbar__view {
      height: $tags-view-height;
      font-size: 0;
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  position: relative;
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
