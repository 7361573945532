/*
 * @Description:资源管理
 * @Author: ChenXueLin
 * @Date: 2021-12-17 15:53:11
 * @LastEditTime: 2022-07-25 13:54:40
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_EWOS = "" // 8802
} = e3Api;
//考核指标列表
export function getCheckIndicatorList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/checkIndicator/listByPage`,
    method: "post",
    data
  });
}
//考核指标编辑
export function updateCheckIndicatorList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/checkIndicator/update`,
    method: "post",
    data
  });
}
//查询费用结算列表分页
export function getSettleFeeList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/listPage`,
    method: "post",
    data
  });
}
//导出费用结算列表分页
export function exportSettleFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/exportSettleFee`,
    method: "post",
    data
  });
}
//获取费用总计
export function getManualSum(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/getManualSum`,
    method: "post",
    data
  });
}
//重新计算
export function recalculate(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/recalculate`,
    method: "post",
    data
  });
}
//批量开始对对账
export function batchStartCheck(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/batchStartCheck`,
    method: "post",
    data
  });
}
//批量确认金额
export function batchConfirm(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/batchConfirm`,
    method: "post",
    data
  });
}
//批量确认结算
export function batchSettled(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/batchSettled`,
    method: "post",
    data
  });
}
//取消对账
export function cancelCheck(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/cancelCheck`,
    method: "post",
    data
  });
}
//批量变更信息
export function batchUpdate(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/batchUpdate`,
    method: "post",
    data
  });
}
//修改费用状态
export function updateManual(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/updateManual`,
    method: "post",
    data
  });
}
//更新结算单信息
export function updateSettleInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/updateSettleInfo`,
    method: "post",
    data
  });
}
//修改费用状态
export function getManualDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/fee/getDetail`,
    method: "post",
    data
  });
}
