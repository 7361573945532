/*
 * @Description: 设备/场景管理
 * @Author: ChenXueLin
 * @Date: 2021-11-04 19:38:52
 * @LastEditTime: 2023-03-10 16:48:39
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_STE = "",
  E3_BL_EWOS = "",
  E3_AG = "" // 8802
} = e3Api;

// 获取场景列表
export function getSceneList(data) {
  return http({
    url: `${host}${E3_AG}/vehicle/listPage`,
    method: "post",
    data
  });
}
// 获取场景业务记录
export function getSceneBussuessHis(data) {
  return http({
    url: `${host}${E3_AG}/vehicle/getVehicleBussuessHis`,
    method: "post",
    data
  });
}
// 导出场景列表
export function exportSceneList(data) {
  return http({
    url: `${host}${E3_AG}/vehicle/export`,
    method: "post",
    data
  });
}
// 更新场景信息
export function updateSceneInfo(data) {
  return http({
    url: `${host}${E3_BL_STE}/scene/updVehicle`,
    method: "post",
    data
  });
}
// 场景详情----》基本资料
export function getSceneBasic(data) {
  return http({
    url: `${host}${E3_BL_STE}/scene/getBasic`,
    method: "post",
    data
  });
}
// 场景详情----》安装照片
export function getScenePicture(data) {
  return http({
    url: `${host}${E3_BL_STE}/vehicle/picture/list`,
    method: "post",
    data
  });
}
// 获取设备列表
export function getEquipList(data) {
  return http({
    url: `${host}${E3_AG}/equip/listPage`,
    method: "post",
    data
  });
}
// 获取设备详情
export function getEquipDetail(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/get`,
    method: "post",
    data
  });
}
// 更新设备信息
export function quiplnfoUpdate(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/updateCommids`,
    method: "post",
    data
  });
}
// 获取设备流转记录
export function getListFlowLog(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/listFlowLog`,
    method: "post",
    data
  });
}
//查看已安装设备
export function getListInstalled(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/listInstalled`,
    method: "post",
    data
  });
}
//查看设备维修记录
export function getRepairRecordList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/work/repairRecord/listPage`,
    method: "post",
    data
  });
}
//查看设备关联单据
export function getTerminalList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/work/terminal/listPage`,
    method: "post",
    data
  });
}
//查看设备费用信息
export function getTerminalPrice(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/price/listItem`,
    method: "post",
    data
  });
}
//查看设备属性配置
export function getTerminalAttr(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/attr/get`,
    method: "post",
    data
  });
}
//修改设备属性配置
export function updateTerminalAttr(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/attr/update`,
    method: "post",
    data
  });
}
//查看设备对接平台信息
export function getAccessList(data) {
  return http({
    url: `${host}${E3_BL_STE}/vehicle/access/listPage`,
    method: "post",
    data
  });
}
//获取设备轨迹数据
export function getEquipHistoryTrackList(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getHistoryTrackList`,
    method: "post",
    data
  });
}
//获取设备温度数据
export function getEquipTemperature(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getTemperature`,
    method: "post",
    data
  });
}
//获取设备油量数据
export function getEquipOilCalc(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getOilCalc`,
    method: "post",
    data
  });
}
//获取设备停车数据
export function getEquipStopTime(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getStopTime`,
    method: "post",
    data
  });
}
//获取设备拍照数据
export function getEquipPicture(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getPicture`,
    method: "post",
    data
  });
}
//获取设备照片地址数据
export function getEquipPictureUrl(data) {
  return http({
    url: `${host}${E3_BL_STE}/equip/getPictureUrl`,
    method: "post",
    data
  });
}
//工程库存查询--分页
export function getEngineerStockNumList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/getEngineerStockNumList`,
    method: "post",
    data
  });
}
//工程设备查询--分页
export function getEngineerStockList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/getEngineerStockList`,
    method: "post",
    data
  });
}
//工程设备查询--分页 导出
export function exportEngineerStockList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/exportEngineerStockList`,
    method: "post",
    data
  });
}
