<!--
 * @Description: svg组件
 * @Author: ChenXueLin
 * @Date: 2021-10-11 11:09:03
 * @LastEditTime: 2021-10-11 11:09:04
 * @LastEditors: ChenXueLin
-->
<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-10-11 10:04:21
 * @LastEditTime: 2021-10-11 10:55:34
 * @LastEditors: ChenXueLin
-->
<template>
  <svg :class="svgClass" aria-hidden="true" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`
      };
    }
  }
};
</script>

<style scoped>
/* .svg-icon {
  width: 100px;
  height: 100px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
} */

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
