/*
 * @Description:
 * @Author: sunxiaodong
 * @Date: 2020-09-24 18:51:31
 * @LastEditors: sunxiaodong
 * @LastEditTime: 2020-09-24 19:00:51
 */
import VrSelect from "./src/main.vue";

VrSelect.install = function(Vue) {
  Vue.component(VrSelect.name, VrSelect);
};

export default VrSelect;
