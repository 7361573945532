/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2022-09-06 11:08:20
 * @LastEditTime: 2022-09-09 17:53:50
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const { host = "", E3_BL_EWOS = "", E3_AG = "" } = e3Api;
//分页查询客户标准作业天数
export function getStandardDayList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/standardDay/listPage`,
    method: "post",
    data
  });
}
//创建客户标准时间
export function createStandardDay(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/standardDay/create`,
    method: "post",
    data
  });
}
//编辑客户标准时间
export function updateStandardDay(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/standardDay/update`,
    method: "post",
    data
  });
}
//编辑客户标准时间
export function updateStatusStandardDay(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/standardDay/updateStatus`,
    method: "post",
    data
  });
}
//分页查询安装工艺
export function getTechnologyList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/technology/listPage`,
    method: "post",
    data
  });
}
//启用/停用安装工艺
export function enableTechnology(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/technology/enable`,
    method: "post",
    data
  });
}
//新增安装工艺
export function createTechnology(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/technology/create`,
    method: "post",
    data
  });
}
//编辑安装工艺
export function updateTechnology(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/basicInfo/technology/update`,
    method: "post",
    data
  });
}
//查询工单客户
export function getWorkCorpList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/workCorpInfo/workCorp/listPage`,
    method: "post",
    data
  });
}
//查询全部任务单
export function getG7TaskList(data) {
  return http({
    url: `${host}${E3_AG}/task/listPage`,
    method: "post",
    data
  });
}
//导出全部任务单
export function exportG7TaskList(data) {
  return http({
    url: `${host}${E3_AG}/export/allTask`,
    method: "post",
    data
  });
}
//分页查询全部调度任务
export function getG7ScheduleTaskList(data) {
  return http({
    url: `${host}${E3_AG}/scheduleTask/listPage`,
    method: "post",
    data
  });
}
//导出查询全部调度任务
export function exportG7ScheduleTaskList(data) {
  return http({
    url: `${host}${E3_AG}/export/exportScheduleTask`,
    method: "post",
    data
  });
}
//分页查询全部工单
export function getG7workOrderList(data) {
  return http({
    url: `${host}${E3_AG}/workOrder/listPage`,
    method: "post",
    data
  });
}
//分页查询全部工单
export function exportG7workOrderList(data) {
  return http({
    url: `${host}${E3_AG}/export/exportWorkItemList`,
    method: "post",
    data
  });
}
//分页查询全部工单
export function exportWorkItemDetailList(data) {
  return http({
    url: `${host}${E3_AG}/export/exportWorkItemDetailList`,
    method: "post",
    data
  });
}
//超期原因
export function getOverdueReason(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/itemWarn/taskItem/overdueReason`,
    method: "post",
    data
  });
}
