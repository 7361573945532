<!--
 * @Description: Navbar
 * @Author: sunxiaodong
 * @Date: 2020-04-07 11:27:49
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-07-27 10:48:39
 -->
<template>
  <div class="navbar">
    <Hamburger v-if="!isScreenfull" />
    <div ref="tagsWrapper" class="tags-wrapper" :class="{ active: isMoveTag }">
      <tags-view ref="tagsView"></tags-view>
      <div class="move-tag">
        <i
          class="previous e6-icon-arrow-l_line"
          @mousedown.prevent="handleMoveTagMousedown(-1)"
          @mouseup.prevent="handleMoveTagMouseup()"
          title="向前移动"
        ></i>
        <i
          class="next e6-icon-arrow-r_line"
          @mousedown.prevent="handleMoveTagMousedown(1)"
          @mouseup.prevent="handleMoveTagMouseup()"
          title="向后移动"
        ></i>
      </div>
    </div>
    <div class="setting-wrapper">
      <span class="notice">
        <!-- v-show="haveMessageOrAlarmOperate" -->
        <!--报警-->
        <el-popover
          placement="bottom"
          trigger="manual"
          popper-class="popover-message"
        >
          <!-- <div class="alarm__content"> -->
          <!--报警列表-->
          <!-- <ul
              v-show="alarmAndMsgInfo.alarmList.length"
              v-loading="alarmInfoLoading"
              class="alarm-list"
            >
              <li
                v-for="alarm in alarmAndMsgInfo.alarmList"
                :key="alarm.id"
                class="alarm-item"
              >
                <div class="alarm-item__top">
                  <span class="alarm-item-name">{{ alarm.vehicleName }}</span>
                  <span class="alarm-item-time">{{ alarm.beginTimeStr }}</span>
                </div>
                <div class="alarm-item__bot">
                  <span class="alarm-item-type">{{
                    alarm.lastAlarmTypeName
                  }}</span>
                  <span class="alarm-item-operate">
                    <span @click="handleDealAlarm(alarm)" class="operate"
                      >处理</span
                    >
                    <span class="split"></span>
                    <span @click="handleIgnoreAlarm(alarm.id)" class="operate"
                      >忽略</span
                    >
                  </span>
                </div>
              </li>
            </ul> -->
          <!--无报警提示-->
          <!-- <span v-show="!alarmAndMsgInfo.alarmList.length" class="alarm-none"
              >暂无报警信息</span
            > -->
          <!--业务报警报表-->
          <!-- <el-button @click="handleTurnToAlarmCenter" class="alarm-link"
              >查看更多报警信息</el-button
            > -->
          <!-- </div> -->
          <i
            @click="handleAudit"
            slot="reference"
            :class="[
              'alarm',
              'e6-icon-risk-alarm_line',
              { 'is-active': alarmAndMsgInfo.alarmCount }
            ]"
          >
            <!--角标-->
            <sup />
          </i>
        </el-popover>
        <!--消息弹窗-->
        <el-popover
          placement="bottom"
          trigger="manual"
          v-model="msgPopoverVisible"
          popper-class="popover-message"
        >
          <!-- <div class="message__content">
            <span
              class="message-info"
              :title="
                alarmAndMsgInfo.lastMsg ? alarmAndMsgInfo.lastMsg.title : ''
              "
              >{{
                alarmAndMsgInfo.lastMsg ? alarmAndMsgInfo.lastMsg.title : ""
              }}</span
            >
            <span v-show="alarmAndMsgInfo.messageCount" class="message-count"
              >共 5 条未读
            </span>
        
            <i
              @click="msgPopoverVisible = false"
              class="message-close e6-icon-delete_line"
            ></i>
          </div> -->
          <i
            @click="handleTurnToMessageCenter"
            slot="reference"
            :class="[
              'message',
              'e6-icon-mail_line',
              { 'is-active': messageNum > 0 }
            ]"
          >
            <sup />
          </i>
        </el-popover>
      </span>
      <el-dropdown
        class="user"
        placement="bottom"
        @command="handleCommand"
        v-if="!isScreenfull"
      >
        <span class="el-dropdown-link">
          <span class="user-name" :title="userName">{{ userName }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePassword">
            <span>修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <!-- <a :href="logoutUrl">
              {{ logout.title }}
            </a> -->
            <!-- <span>{{ logout.title }}</span> -->
            <span>退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!--处理报警组件-->
      <!-- <alarm-detail ref="alarmDetail" @refresh="handleEmitUpDateAlarmList" /> -->
      <!--修改密码弹窗-->
      <change-password ref="changePasswordRef" />
    </div>
    <!-- <el-dialog
      v-dialogDrag
      title="修改密码"
      :visible="editPwdVisible"
      width="500px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="editPwd-dialog"
    >
      <el-form
        ref="editPwdForm"
        label-width="100px"
        :model="editPwdForm"
        :rules="editPwdFormRules"
        :inline="true"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model.trim="editPwdForm.oldPassword"
            show-password
            placeholder="原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model.trim="editPwdForm.newPassword"
            placeholder="新密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPasswordConfirm">
          <el-input
            v-model.trim="editPwdForm.newPasswordConfirm"
            placeholder="确认新密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirmUpdatePwd">确定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import TagsView from "../TagsView/index.vue";
import Hamburger from "../Hamburger/index.vue";
import changePassword from "./changePassword";
/* import {
  addResizeListener,
  removeResizeListener
} from "e6-base-util/src/resize-event"; */

import {
  addResizeListener,
  removeResizeListener
} from "e6yun-ui/lib/utils/resize-event";

import { mapGetters } from "vuex";

const INTERVAL = 30; // 鼠标down 和 up 之间的时间差，判断是长按，还是click，单位毫秒数

import {
  batchIgnore,
  fetchAlarmCountAndLastMsg, // 查询报警总数与最近一条消息
  fetchTopAlarmList, // 查询最近五条报警信息
  getUnreadMessageCount
} from "@/api";
import { signOut } from "@/api/login";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
import baseMixins from "@/mixins/base";

import Cookies from "js-cookie";

export default {
  components: { TagsView, Hamburger, changePassword },
  mixins: [baseMixins],

  data() {
    return {
      isMoveTag: false,
      eventTimer: null,
      moveTimer: null,
      moveDir: 1,
      sTime: 0,
      messageNum: 0,
      eventType: "mouseup", // click | mouseup
      /* ***************************
       * 报警与消息相关
       * ***************************/
      // 消息弹窗可见性
      msgPopoverVisible: false,
      alarmInfoLoading: false,
      alarmAndMsgTimer: null,
      alarmAndMsgInfo: {
        alarmCount: 0,
        alarmList: [],
        messageCount: 0,
        lastMsg: {}
      },
      userName: "" // 用户名称
    };
  },

  computed: {
    // ...mapGetters(["userInfo", "allMenu", "visitedViews"]),
    ...mapGetters(["allMenu", "visitedViews", "isScreenfull"]),

    scrollWrapper() {
      console.log(this.isScreenfull, "isScreenfull271");
      return this.$refs.tagsView.$refs.scrollPane.$refs.scrollContainer.$refs
        .wrap;
    },

    logout() {
      return this.allMenu["SignOut"];
    },

    logoutUrl() {
      // return `${this.logout.url}?pBackUrl=${process.env.VUE_APP_DOMAIN}/#/home`;
      return "/#/login";
    },

    // 是否有报警或消息的权限
    haveMessageOrAlarmOperate() {
      return (
        this.showOperateBtn(3, "BusinessAlarmReport3") ||
        this.showOperateBtn(3, "messageCenter3")
      );
    }
  },

  watch: {
    visitedViews() {
      this.$nextTick(() => {
        this.getScroll();
      });
    }
  },

  methods: {
    handleMoveTagMousedown(dir) {
      this.moveDir = dir * 1;

      this.sTime = new Date().getTime();
      this.eventType = "mouseup";

      clearTimeout(this.eventTimer);

      this.timer = setTimeout(() => {
        if (this.eventType === "click") {
          this.scrollWrapper.scrollLeft += 80 * this.moveDir;
        } else {
          clearInterval(this.moveTimer);

          this.moveTimer = setInterval(() => {
            this.scrollWrapper.scrollLeft += 4 * this.moveDir;
          }, 10);
        }
      }, INTERVAL);
    },

    handleMoveTagMouseup() {
      let currentTime = new Date().getTime();
      if (currentTime - this.sTime > INTERVAL - 10) {
        clearInterval(this.moveTimer);
      } else {
        this.eventType = "click";
      }
    },

    getScroll() {
      this.isMoveTag =
        this.scrollWrapper.scrollWidth - this.scrollWrapper.clientWidth > 0;
    },

    handleCommand(val) {
      switch (val) {
        case "logout":
          this.handleLogout();
          break;
        case "changePassword":
          this.handleChangePassword();
          break;
      }
    },
    // 修改密码
    handleChangePassword() {
      this.$refs.changePasswordRef.show();
    },

    // 退出登录
    async handleLogout() {
      try {
        let token = Cookies.get("E3RequestToken");
        console.log(token, "token---");
        let res = await signOut({
          token
        });
        if (res.code === "OK") {
          // 移除cookies 和 localDtorage
          window.localStorage.clear();
          this.$router.push("/login?feishuNeedAutoLogin=1"); // 地址参数仅用于控制飞书内置浏览器退出后不自动登录
          Cookies.remove("PhoneExt");
          eventBus.$emit("closeWebsocket");
        }
      } catch (error) {
        printError(error);
      }
    },
    // //关闭websocket
    // websocketclose(e) {
    //   //关闭
    //   console.log("断开连接", e);
    // },
    //获取未读数量
    async getMessageNum() {
      try {
        let res = await getUnreadMessageCount();
        this.messageNum = res.data.unreadMessageCount;
      } catch (e) {
        printError(e);
      }
    },
    /**
     * @desc 从storage中拿出之前的数据，节流
     * @return { Object } 节流后的storage中的数据
     */
    getPrevAlarmInfoByKey() {
      try {
        // 上一次的数据
        const prevDataStr = window.localStorage.getItem("AlarmCountData");
        // 没有数据
        if (!prevDataStr) return null;
        const { timestamp, data } = JSON.parse(prevDataStr);

        // 没有时间戳或者为0或者没有数据
        if (!timestamp || !data) return null;

        // 报警数据的间隔已超过1分钟
        if (new Date().getTime() - timestamp > 1e3 * 60) return null;

        // 返回之前请求的数据
        return data;
      } catch (e) {
        printError(e);
        return null;
      }
    },

    /**
     * @desc 加载报警总数和最后一条信息
     * @param { Boolean } reload 是否不取缓存中的数据
     * @return
     */
    async fetchAlarmAndLastMsg(reload) {
      clearTimeout(this.alarmAndMsgTimer);
      if (!this.haveMessageOrAlarmOperate) return;

      try {
        this.alarmInfoLoading = true;
        let data = reload ? null : this.getPrevAlarmInfoByKey("AlarmCountData");
        // 不存在旧数据数据时请求新的数据
        if (!data) {
          // 设置默认数据
          data = {
            alarmCount: 0,
            alarmList: [],
            messageCount: 0,
            lastMsg: null
          };

          // 加载数据
          const [countRes, alarmListRes] = await Promise.all([
            fetchAlarmCountAndLastMsg(),
            fetchTopAlarmList()
          ]);

          // 设置报警与消息条数
          if (
            countRes.code === "OK" &&
            countRes.result &&
            alarmListRes.code === "OK"
          ) {
            // 给信息设置默认条数
            if (!Array.isArray(countRes.result.theLatestNewslist))
              countRes.result.theLatestNewslist = [];

            // 给报警设置默认值
            if (!Array.isArray(alarmListRes.result)) alarmListRes.result = [];

            data = {
              alarmCount: countRes.result.alarmCount,
              alarmList: alarmListRes.result,
              messageCount: countRes.result.messageCount,
              lastMsg: countRes.result.theLatestNewslist[0]
            };

            // 记录在localStorage中
            window.localStorage.setItem(
              "AlarmCountData",
              JSON.stringify({
                timestamp: new Date().getTime(), // 设置时间戳
                data
              })
            );
          }
        }

        // 当有数据时打开消息提示，否则关闭
        this.msgPopoverVisible = data.lastMsg && !reload;

        // 设置报警信息
        this.alarmAndMsgInfo = data;
      } catch (e) {
        printError(e);
      } finally {
        this.alarmInfoLoading = false;
        // 60秒后再请求数据
        this.alarmAndMsgTimer = setTimeout(this.fetchAlarmAndLastMsg, 1e3 * 60);
      }
    },

    // 忽略按钮
    async handleIgnoreAlarm(id) {
      try {
        this.alarmInfoLoading = true;
        let res = await batchIgnore({ ids: [id] });
        if (res.code === "OK") {
          eventBus.$emit("UPDATE_ALARM_LIST");
        }
      } catch (err) {
        printError(err);
      } finally {
        this.alarmInfoLoading = false;
      }
    },

    // 触发全局监听全局事件报警列表刷新事件
    handleEmitUpDateAlarmList() {
      eventBus.$emit("UPDATE_ALARM_LIST");
    },

    // 处理按钮
    async handleDealAlarm(alarmInfo) {
      this.$refs.alarmDetail.openDialog(alarmInfo, 2);
    },

    // 跳转到消息中心
    handleTurnToMessageCenter() {
      this.routerPush({
        name: "messageCenter",
        params: {
          refresh: false
        }
      });
    },
    // 跳转到审核列表
    handleAudit() {
      this.routerPush({
        name: "auditList",
        params: {
          refresh: false
        }
      });
    }
  },

  mounted() {
    // 获取用户名
    this.userName = Cookies.get("userName");
    // 监听全局事件报警列表刷新事件
    eventBus.$on("UPDATE_ALARM_LIST", () => {
      this.fetchAlarmAndLastMsg(true);
    });

    this.fetchAlarmAndLastMsg();
    addResizeListener(this.$refs.tagsWrapper, this.getScroll);
    this.getMessageNum();
  },

  beforeDestroy() {
    removeResizeListener(this.$refs.tagsWrapper, this.getScroll);

    // 移除报警列表刷新事件
    eventBus.$off("UPDATE_ALARM_LIST");

    clearTimeout(this.alarmAndMsgTimer);
    clearTimeout(this.eventTimer);
    clearInterval(this.moveTimer);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

.navbar {
  position: relative;
  height: $header-height--h;
  // line-height: $header-height--h;
  & > span {
    margin-left: 40px;
  }
  .settings {
    position: absolute;
    right: 0;
    height: $header-height--h;
    padding: 0 20px;
    line-height: $header-height--h;
    background-color: rgba(255, 255, 255, 0.1);
    .el-dropdown-link {
      display: block;
      height: 100%;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/utils.scss";

$userWidth: 200px;
.vertical {
  .navbar {
    position: relative;

    .tags-wrapper {
      position: absolute;
      left: 42px;
      top: 0;
      bottom: 0;
      right: $userWidth + 30px + 20px;
      .move-tag {
        display: none;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        height: $header-height--h;
        right: -40px;
        width: 30px;
        i {
          height: 26px;
          line-height: 26px;
          width: 14px;
          color: #9ea0a5;
          font-size: 14px;
          text-align: center;
          background-color: #edf0f5;
          border-radius: 2px;
          cursor: pointer;
          &.active,
          &:hover {
            color: #87d5f1;
            background-color: #daf2fb;
          }
        }
      }
      &.active {
        .move-tag {
          display: inline-flex;
        }
      }
    }
    .setting-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      color: #79889a;
      background-color: #f5f7fa;
      line-height: $header-height--v;
      .notice {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 0 10px;
        border-left: 1px solid #e8f0f8;
        box-sizing: border-box;
        &:before,
        &:after {
          content: "";
          display: block;
        }

        > span {
          margin: 0 5px;
        }
      }
      .alarm,
      .message {
        cursor: pointer;
      }
      .alarm,
      .message {
        position: relative;
        outline: none;
        sup {
          display: none;
          position: absolute;
          top: -4%;
          left: 75%;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #f56c6c;
        }
        &.is-active {
          sup {
            display: block;
          }
        }
      }
      .user {
        width: calc(200px - 68px);
        border-left: 1px solid #e8f0f8;
        vertical-align: top;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        .el-dropdown-link {
          color: #6b7d96;
        }
        .user-name {
          display: inline-block;
          max-width: 86px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: top;
        }
      }
    }
  }
}

/*.报警弹窗*/
.popover-alarm {
  border: 1px solid #ebeef5;
  border-radius: 0;
  padding: 0;

  .alarm {
    &__content {
      box-sizing: border-box;
      width: 300px;
    }

    &-list {
      padding: 15px 15px 0;
    }

    &-item {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 1;

      &:not(:nth-of-type(1)) {
        margin-top: 10px;
      }

      &__top,
      &__bot {
        display: flex;
        justify-content: space-between;
      }

      &__bot {
        margin-top: 10px;
      }

      &-name {
        color: #444444;
      }

      &-time {
        color: #aaaaaa;
      }

      &-type {
        color: #888888;
      }

      &-operate {
        color: #46bfea;

        .operate {
          cursor: pointer;
        }

        .split {
          margin: 0 7px;
          border-right: 1px solid #46bfea;
        }
      }
    }

    &-none {
      display: block;
      padding: 10px 0;
      text-align: center;
      font-size: 12px;
    }

    &-link {
      border: none;
      width: 100%;
      height: 36px;
      font-size: 12px;
      color: #5a667d;
      background: #f2f2f2;
    }
  }
}

/*消息弹窗*/
.popover-message {
  padding: 0;
  border: 0;
  background: transparent;

  .popper__arrow:after {
    top: 0 !important;
    border-bottom-color: rgba(227, 151, 38, 0.7) !important;
  }

  .message {
    &__content {
      position: relative;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px 25px 12px 15px;
      width: 300px;
      height: 68px;
      background: rgba(227, 151, 38, 0.7);
    }

    &-info,
    &-count {
      display: block;
      font-size: 12px;
      line-height: 22px;
      color: #ffffff;
      @include utils-ellipsis;
    }

    &-close {
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.editPwd-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
