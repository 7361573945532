/*
 * @Description: 日志记录接口
 * @Author: ChenXueLin
 * @Date: 2022-01-21 10:36:08
 * @LastEditTime: 2022-05-10 11:09:45
 * @LastEditors: LiangYiNing
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_AG = "", // 8802
  E3_BL_EWOS = ""
} = e3Api;
//获取动态表头
export function getHeader(data) {
  return http({
    url: `${host}${E3_AG}/his/getHeader`,
    method: "post",
    data
  });
}
//获取对比表头
export function getCompareHeader(data) {
  return http({
    url: `${host}${E3_AG}/his/getCompareHeader`,
    method: "post",
    data
  });
}
//公司日志列表-查询
export function getCorpHis(data) {
  return http({
    url: `${host}${E3_AG}/corpHis/listPage`,
    method: "post",
    data
  });
}
//公司日志列表-导出
export function getCorpHisExport(data) {
  return http({
    url: `${host}${E3_AG}/corpHis/export`,
    method: "post",
    data
  });
}
//联系人日志列表-查询
export function getCorpContactHis(data) {
  return http({
    url: `${host}${E3_AG}/corpContactHis/listPage`,
    method: "post",
    data
  });
}
//联系人日志列表-导出
export function corpContactHisExport(data) {
  return http({
    url: `${host}${E3_AG}/corpContactHis/export`,
    method: "post",
    data
  });
}
//开票日志列表-查询
export function getInvoiceHis(data) {
  return http({
    url: `${host}${E3_AG}/invoiceHis/listPage`,
    method: "post",
    data
  });
}
//开票日志列表-导出
export function getInvoiceHisExport(data) {
  return http({
    url: `${host}${E3_AG}/invoiceHis/export`,
    method: "post",
    data
  });
}
//硬件商品管理日志
export function getGoodsHis(data) {
  return http({
    url: `${host}${E3_AG}/goodsHis/listHardPage`,
    method: "post",
    data
  });
}
//软件商品管理日志
export function getSoftHis(data) {
  return http({
    url: `${host}${E3_AG}/goodsHis/listSoftPage`,
    method: "post",
    data
  });
}
//服务商品管理日志
export function getServiceHis(data) {
  return http({
    url: `${host}${E3_AG}/goodsHis/listServicePage`,
    method: "post",
    data
  });
}
//服务单日志分页列表
export function getServiceTaskHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceWork/listPage`,
    method: "post",
    data
  });
}
//服务单维修申请任务项日志分页列表
export function getServiceRepairHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/repairPage`,
    method: "post",
    data
  });
}
//服务单场景变更任务项日志分页列表
export function getServiceSceneChangeHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/sceneChangePage`,
    method: "post",
    data
  });
}
//服务单开通申请任务项日志分页列表
export function getServiceOpenHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/openPage`,
    method: "post",
    data
  });
}
//服务单报停申请任务项日志分页列表
export function getServiceStopHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/stopPage`,
    method: "post",
    data
  });
}
//服务单设置打印信息任务项日志分页列表
export function getServicePrintHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/printPage`,
    method: "post",
    data
  });
}
//服务单对接申请任务项日志分页列表
export function getServiceDockHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/dockPage`,
    method: "post",
    data
  });
}
//服务单咨询回复任务项日志分页列表
export function getServiceReplyHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/serviceItem/replyPage`,
    method: "post",
    data
  });
}

/******任务单日志******/
//任务单日志分页列表
export function getTaskOrderHis(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/task/listPage`,
    method: "post",
    data
  });
}
//调度任务日志
export function getScheduleTaskHisList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/schedule/log/getScheduleTaskHisList`,
    method: "post",
    data
  });
}
//调度信息日志
export function getScheduleInfoHisList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/schedule/log/getScheduleHisList`,
    method: "post",
    data
  });
}
//获取任务项日志
export function getTaskItemHisList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/taskItem/listPage`,
    method: "post",
    data
  });
}
//获取任务项商品列表
export function getTaskItemProductList(data = {}) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/product/list/${data.requestId}/${data.itemId}`,
    method: "post"
  });
}
//获取任务项日志商品字段对比信息
export function getTaskItemProductContrast(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/operation/product/contrast`,
    method: "post",
    data
  });
}
