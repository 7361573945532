/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-10-11 10:05:05
 * @LastEditTime: 2021-10-11 13:34:41
 * @LastEditors: ChenXueLin
 */
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon"; // svg组件
// 注册全局组件
Vue.component("svg-icon", SvgIcon);
const requireAll = reqireContext => reqireContext.keys().map(reqireContext);
const req = require.context("@/icons/svg/", false, /\.svg$/);
requireAll(req);
