/*
 * @Description: 商品管理
 * @Author: ChenXueLin
 * @Date: 2021-11-15 18:53:44
 * @LastEditTime: 2021-12-27 19:06:57
 * @LastEditors: LiangYiNing
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_BASIC = "" // 8802
} = e3Api;

/**********商品管理***************/
/********硬件商品************/

// 获取硬件商品列表查询
export function getHardwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/hardwareGoods/listPage`,
    method: "post",
    data
  });
}
// 导出硬件商品列表查询
export function exportHardwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/hardwareGoods/export`,
    method: "post",
    data
  });
}
// 获取硬件商品详情
export function hardwareGoodsDetail(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/hardwareGoods/get`,
    method: "post",
    data
  });
}
// 修改硬件商品
export function updateHardwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/hardwareGoods/update`,
    method: "post",
    data
  });
}
/********软件商品************/

// 获取软件商品列表查询
export function getSoftwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/softwareGoods/listPage`,
    method: "post",
    data
  });
}
// 导出软件商品列表查询
export function exportSoftwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/softwareGoods/export`,
    method: "post",
    data
  });
}
// 获取软件商品详情
export function softwareGoodsDetail(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/softwareGoods/get`,
    method: "post",
    data
  });
}
// 修改软件商品
export function updateSoftwareGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/softwareGoods/update`,
    method: "post",
    data
  });
}
/********服务商品************/

// 获取服务商品列表查询
export function getServiceGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/serviceGoods/listPage`,
    method: "post",
    data
  });
}
// 导出服务商品列表查询
export function exportServiceGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/serviceGoods/export`,
    method: "post",
    data
  });
}
// 获取服务商品详情
export function serviceGoodsDetail(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/serviceGoods/get`,
    method: "post",
    data
  });
}
// 更新服务商品信息
export function updateServiceGoods(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/serviceGoods/update`,
    method: "post",
    data
  });
}

/********商品属性************/
// 商品属性列表
export function getGoodsAttrList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/listPage`,
    method: "post",
    data
  });
}
// 商品属性列表 字表查询
export function getGoodsAttrSonList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/listAttrPage`,
    method: "post",
    data
  });
}
// 商品属性新增
export function createGoodsAttr(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/create`,
    method: "post",
    data
  });
}
// 商品属性删除
export function batchDeleteGoodsAttr(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/batchDelete`,
    method: "post",
    data
  });
}
// 商品属性详情
export function getGoodsAttrItem(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/get`,
    method: "post",
    data
  });
}
// 商品属性详情
export function updateGoodsAttr(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/goodsAttr/update`,
    method: "post",
    data
  });
}
