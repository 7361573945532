"use strict";

exports.__esModule = true;

var _vue = require("vue");

var _vue2 = _interopRequireDefault(_vue);

var _dom = require("./dom");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var nodeList = [];
var ctx = "@@clickoutsideContext";

var startClick = void 0;
var seed = 0;

!_vue2.default.prototype.$isServer &&
  (0, _dom.on)(document, "mousedown", function(e) {
    return (startClick = e);
  });

!_vue2.default.prototype.$isServer &&
  (0, _dom.on)(document, "mouseup", function(e) {
    nodeList.forEach(function(node) {
      return node[ctx].documentHandler(e, startClick);
    });
  });

function createDocumentHandler(el, binding, vnode) {
  return function() {
    var mouseup =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var mousedown =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (
      !vnode ||
      !vnode.context ||
      !mouseup.target ||
      !mousedown.target ||
      el.contains(mouseup.target) ||
      el.contains(mousedown.target) ||
      el === mouseup.target ||
      (vnode.context.popperElm &&
        (vnode.context.popperElm.contains(mouseup.target) ||
          vnode.context.popperElm.contains(mousedown.target)))
    )
      return;

    if (
      binding.expression &&
      el[ctx].methodName &&
      vnode.context[el[ctx].methodName]
    ) {
      vnode.context[el[ctx].methodName]();
    } else {
      el[ctx].bindingFn && el[ctx].bindingFn();
    }
  };
}

/**
 * v-clickoutside
 * @desc 点击元素外面才会触发的事件
 * @example
 * ```vue
 * <div v-element-clickoutside="handleClose">
 * ```
 */
exports.default = {
  bind: function bind(el, binding, vnode) {
    nodeList.push(el);
    var id = seed++;
    el[ctx] = {
      id: id,
      documentHandler: createDocumentHandler(el, binding, vnode),
      methodName: binding.expression,
      bindingFn: binding.value
    };
  },
  update: function update(el, binding, vnode) {
    el[ctx].documentHandler = createDocumentHandler(el, binding, vnode);
    el[ctx].methodName = binding.expression;
    el[ctx].bindingFn = binding.value;
  },
  unbind: function unbind(el) {
    var len = nodeList.length;

    for (var i = 0; i < len; i++) {
      if (nodeList[i][ctx].id === el[ctx].id) {
        nodeList.splice(i, 1);
        break;
      }
    }
    delete el[ctx];
  }
};
