/*
 * @Description: websocket方法
 * @Author: ChenXueLin
 * @Date: 2022-03-24 14:28:08
 * @LastEditTime: 2022-08-02 18:17:05
 * @LastEditors: ChenXueLin
 */
export default {
  data() {
    return {
      timer: null,
      timeoutObj: null
      // errorCount: 0
    };
  },
  methods: {
    initWebSocket() {
      //初始化websocket
      //e3yanlian.e6gpshk.com演练环境地址
      //e3aly.e6gpshk.com/开发环境
      //https://e3.e6haoyilian.com 线上地址
      //e3.e6gpshk.com  测试环境
      let PhoneExt = Cookies.get("PhoneExt");
      const targetUrl =
        window.location.host.indexOf("localhost") != -1
          ? "e3.e6gpshk.com"
          : window.location.host;
      const wsuri = `wss://${targetUrl}/websocket/E3-BASE-EXTAPI-WEBSOCKET/websocket/callPhone/${PhoneExt}?token=${Cookies.get(
        "E3RequestToken"
      )}`;
      if (this.websock && this.websock.readyState == 1) {
        this.close();
      }
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    startTimer() {
      //每隔1分钟重新连接一次
      let that = this;
      clearTimeout(that.timer);
      that.timer = setTimeout(() => {
        // this.close();
        clearTimeout(this.timer);
        clearInterval(this.timeoutObj);
        console.log("每隔1分钟重连一次");
        that.initWebSocket();
      }, 60000);
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      console.log("建立成功56===");
      this.startTimer();
      if (this.timeoutObj) {
        clearInterval(this.timeoutObj);
      }
      let that = this;
      that.timeoutObj = setInterval(() => {
        console.log("每隔30s发送一次心跳");
        that.websocketsend("ping");
      }, 30000);
    },
    websocketonerror(e) {
      // error
      console.log("连接报错", e);
      //连接建立失败重连
      if (
        Cookies.get("E3RequestToken") &&
        Cookies.get("PhoneExt") &&
        Cookies.get("PhoneExt") != "null"
      ) {
        setTimeout(this.initWebSocket, 60000);
      } else {
        this.close();
      }
    },
    websocketonmessage(e) {
      //数据接收
      const resData = this.isJsonString(e.data) ? JSON.parse(e.data) : {};
      console.log(resData, "接收到的数据");
      if (resData.customerNumber) {
        // callCenter/workbench
        let mainUniqueIdList = window.localStorage.getItem("mainUniqueIdList")
          ? JSON.parse(window.localStorage.getItem("mainUniqueIdList"))
          : [];
        if (mainUniqueIdList.includes(resData.mainUniqueId)) {
          return;
        }
        let newpage = this.$router.resolve({
          name: "callCenter/workbench",
          query: {
            customerNumber: resData.customerNumber,
            mainUniqueId: resData.mainUniqueId,
            callType: resData.callType,
            firstServer: resData.firstServer,
            returnTypeCode: resData.revisitTypeCode,
            revisitId: resData.revisitId,
            secondService: resData.secondService,
            corpId: resData.corpId,
            needClose: true
          }
        });
        mainUniqueIdList.push(resData.mainUniqueId);
        window.localStorage.setItem(
          "mainUniqueIdList",
          JSON.stringify(mainUniqueIdList)
        );
        window.open(newpage.href, "_blank");
      }
      if (resData.customerNumber || resData.message) {
        this.startTimer();
      }
    },
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接:" + e);
    },
    close() {
      clearTimeout(this.timer);
      clearInterval(this.timeoutObj);
      this.websock.close();
    }
  }
};
