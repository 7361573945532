/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2021-10-11 13:34:09
 * @LastEditors: ChenXueLin
 */
const state = {
  menu: [],
  addRoutes: [],
  allMenu: {},
  // 页面按钮权限
  operationIds: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
  },
  SET_MENU: (state, menu) => {
    state.menu = menu;
  },
  SET_ALL_MENU: (state, allMenu) => {
    state.allMenu = allMenu;
  },
  // 设置按钮权限
  SET_OPERATION_IDS(state, payload) {
    state.operationIds = payload;
  },
  RESET_ALL_STATE: state => {
    state.menu = [];
    state.allMenu = [];
    state.addRoutes = [];
    state.operationIds = [];
  }
};

const actions = {
  setRoutes({ commit }, routes) {
    return new Promise(resolve => {
      let arr = [];
      if (Array.isArray(routes)) {
        arr = routes;
        commit("SET_ROUTES", routes);
      }
      resolve([...arr]);
    });
  },

  setMenu({ commit }, menu) {
    return new Promise(resolve => {
      let arr = [];
      if (Array.isArray(menu)) {
        arr = menu;
        commit("SET_MENU", menu);
      }
      resolve([...arr]);
    });
  },

  setAllMenu({ commit }, allMenu) {
    return new Promise(resolve => {
      let obj = {};
      if (Object.prototype.toString(allMenu)) {
        obj = allMenu;
        commit("SET_ALL_MENU", allMenu);
      }
      resolve({ ...obj });
    });
  }
};

export const namespace = "permission";

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
