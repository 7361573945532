/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2022-07-13 10:24:26
 * @LastEditTime: 2022-07-22 14:31:15
 * @LastEditors: ChenXueLin
 */
import axios from "axios";

const isNewVersion = () => {
  let url = `//${window.location.host}/version.json?t=${new Date().getTime()}`;
  axios.get(url).then(res => {
    if (res.status === 200) {
      let vueVersion = res.data.version;
      let localVueVersion = localStorage.getItem("vueVersion");
      localStorage.setItem("vueVersion", vueVersion);
      if (localVueVersion && localVueVersion != vueVersion) {
        alert("检测到新版本，请点击确认刷新页面哦");
        window.location.reload(true);
        return;
      }
    }
  });
};

export default {
  isNewVersion
};
