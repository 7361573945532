/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-16 15:59:31
 * @LastEditTime: 2022-09-01 14:35:20
 * @LastEditors: ChenXueLin
 */
/**
 * constantRoutes
 * 一些基础页面不需要权限控制的页面
 */

export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index")
  }
];
let allMenuData;
let newRoute = {};
const handleRedirect = (menuTree = []) => {
  let firstMenu = [menuTree[0]];
  if (firstMenu.length) {
    firstMenu.forEach(item => {
      if (item.children && item.children.length > 0) {
        handleRedirect(item.children);
      } else {
        for (let key in allMenuData) {
          if (key == item.menuKey) {
            let routeOperationList = allMenuData[key].operationList;
            let name = "";
            if (routeOperationList.length) {
              name = routeOperationList[0].route;
            }
            newRoute = { name };
          }
        }
      }
    });
  }
  return newRoute;
};

export const handleRoute = (menuTree, allMenu) => {
  allMenuData = allMenu;
  // debugger;
  let redirectRoute = menuTree.length
    ? handleRedirect(menuTree, allMenu)
    : "/login";
  const dynamicalRoutes = [
    {
      path: "/",
      component: () => import("@/views/layout"),
      children: [
        {
          path: "redirect/:path+",
          component: () => import("@/views/redirect/index")
        },
        {
          path: "/",
          // redirect: { name: "workOrderManage/allWorkOrder" }
          redirect: redirectRoute
          // { name: "orderManage/createOrder" }
        }
      ]
    }
  ];
  return dynamicalRoutes;
};
