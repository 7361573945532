/*
 * @Description: 用户管理
 * @Author: ChenXueLin
 * @Date: 2021-11-02 10:25:10
 * @LastEditTime: 2022-03-10 14:20:54
 * @LastEditors: LiangYiNing
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_EWOS = "",
  E3_BL_CORPORATION = ""
  // E3_BASE_USER_SYSTEM = ""
} = e3Api;

/********** 个人用户  *********/
// 组织架构树形列表
export function getFrameworkTree(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/framework/tree`,
    method: "post",
    data
  });
}
// 获取个人用户列表
export function getUserList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/listPage`,
    method: "post",
    data
  });
}
// 获取用户角色下拉框
export function getUserRoleList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/listIdAndNames`,
    method: "post",
    data
  });
}
// 批量启用账号
export function batchEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/batchEnable`,
    method: "post",
    data
  });
}
// 批量禁用账号
export function batchDisEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/batchDisEnable`,
    method: "post",
    data
  });
}
// 导出用户列表
export function exportUserList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/export`,
    method: "post",
    data
  });
}
// 添加用户
export function saveUser(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/create`,
    method: "post",
    data
  });
}
//获取岗位下拉框
export function jobList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/frameworkPost/listIdAndNames`,
    method: "post",
    data
  });
}
// 获取用户基本信息
export function getBasic(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/get`,
    method: "post",
    data
  });
}
// 工程师角色信息负责区域
export function engineerArea(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/getAreaInfo`,
    method: "post",
    data
  });
}
//工程师认证信息
export function engineerAuth(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/engineer/getAuth`,
    method: "post",
    data
  });
}
//工程师编辑认证信息
export function engineerUpdateAuth(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/engineer/updateAuth`,
    method: "post",
    data
  });
}
// 编辑基本信息
export function updateBasic(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/update`,
    method: "post",
    data
  });
}
// 停用账号
export function disableAccount(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/disEnable`,
    method: "post",
    data
  });
}
// 重置密码
export function resetPwd(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/restPassword`,
    method: "post",
    data
  });
}
//修改密码
export function updatePassword(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/updatePassword`,
    method: "post",
    data
  });
}
// 启用账号
export function enableAccount(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/enable`,
    method: "post",
    data
  });
}
//获取用户认证信息
export function getUserAuth(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/getUserAuth`,
    method: "post",
    data
  });
}
//编辑用户认证信息
export function editUserAuth(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/saveUserAuth`,
    method: "post",
    data
  });
}
//工程师名下设备
export function getEngineerEquipment(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/engineer/equipList`,
    method: "post",
    data
  });
}
//转移设备
export function moveEquipe(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/engineer/change`,
    method: "post",
    data
  });
}
//名下任务项
export function engineerTaskList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/engineer/taskList`,
    method: "post",
    data
  });
}
/********** 用户角色  *********/
// 获取角色权限列表
export function getRoleList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/listPage`,
    method: "post",
    data
  });
}
//批量启用角色权限
export function roleBatchEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/batchEnable`,
    method: "post",
    data
  });
}
//批量禁用角色权限
export function roleBatchDisEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/batchDisEnable`,
    method: "post",
    data
  });
}
//添加角色
export function addRole(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/create`,
    method: "post",
    data
  });
}
//个人用户详情获取用户角色权限
export function getUserRoleTree(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/getTree`,
    method: "post",
    data
  });
}
//获取角色树
export function roleTree(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/getTree`,
    method: "post",
    data
  });
}
//获取角色详情
export function getRoleDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/get`,
    method: "post",
    data
  });
}
//启用角色
export function enableRole(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/enable`,
    method: "post",
    data
  });
}
//停用角色
export function roleDisEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/disEnable`,
    method: "post",
    data
  });
}
//修改角色信息
export function updateRole(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/role/update`,
    method: "post",
    data
  });
}

/***********管理员权限***************/

// 获取管理员权限列表
export function getAdminList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/listPage`,
    method: "post",
    data
  });
}

// 获取管理员详情
export function getAdminDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/get`,
    method: "post",
    data
  });
}
// 获取管理员树
export function adminTree(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/getTree`,
    method: "post",
    data
  });
}
// 启用管理员
export function adminEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/enable`,
    method: "post",
    data
  });
}
// 停用管理员
export function adminDisEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/stop`,
    method: "post",
    data
  });
}
// 删除管理员
export function deleteAdmin(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/delete`,
    method: "post",
    data
  });
}
// 添加管理员
export function addAdmin(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/create`,
    method: "post",
    data
  });
}
// 编辑管理员
export function updateAdmin(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/manager/update`,
    method: "post",
    data
  });
}
/********关联角色岗位**********/
//获取岗位列表
export function getJobList(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/list`,
    method: "post",
    data
  });
}
//添加岗位
export function addPostRole(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/create`,
    method: "post",
    data
  });
}
//获取岗位详情
export function getJobDetail(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/get`,
    method: "post",
    data
  });
}
//编辑岗位
export function editPostRole(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/update`,
    method: "post",
    data
  });
}
//岗位批量启用
export function postRoleEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/enable`,
    method: "post",
    data
  });
}
//岗位批量禁用
export function postRoleDisEnable(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/disEnable`,
    method: "post",
    data
  });
}
//岗位批量删除
export function postRoleDelete(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/postRole/delete`,
    method: "post",
    data
  });
}
